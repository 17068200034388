import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map, startWith, delay} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';
import {Product} from '../classes/product';
import {AppConfig} from '../../app.config';
import {Page} from '../classes/page.model';

const state = {
    products: JSON.parse(localStorage['products'] || '[]'),
    wishlist: JSON.parse(localStorage['wishlistItems'] || '[]'),
    compare: JSON.parse(localStorage['compareItems'] || '[]'),
    cart: JSON.parse(localStorage['cartItems'] || '[]')
};

@Injectable({
    providedIn: 'root'
})
export class ProductService {

    public Currency = {name: 'Dollar', currency: 'USD', price: 1, code: 'USD'}; // Default Currency
    public OpenCart: boolean = false;
    public Products;
    public url;
    public urlmatriz;
    public urlmasvendidos;
    public urlpreciodescuento;

    constructor(private http: HttpClient, private config: AppConfig,
                private toastrService: ToastrService) {
        this.urlmatriz = this.config.getEnv('API_URL') + '/api/shopcart/vwitemTienda/';
        this.urlmasvendidos = this.config.getEnv('API_URL') + '/api/shopcart/masvendidos/';
        //this.urlmatriz = this.config.getEnv('API_URL') + '/api/shopcart/vwitemTiendaMatriz/';
        this.urlpreciodescuento = this.config.getEnv('API_URL') + '/api/carpreciodescuento/';
    }

    /*
      ---------------------------------------------
      ---------------  Product  -------------------
      ---------------------------------------------
    */

    // Product
    private get products(): Observable<Product[]> {
        this.Products = this.http.get<Product[]>(this.url).pipe(map(data => data));
        this.Products.subscribe(next => {
            localStorage['products'] = JSON.stringify(next);
        });
        return this.Products = this.Products.pipe(startWith(JSON.parse(localStorage['products'] || '[]')));
    }

    // Get Products
    public get getProducts(): Observable<Product[]> {
        return this.products;
    }

    // Get Products By Slug
    public getProductBySlug(slug: string): Observable<Product> {
        return this.products.pipe(map(items => {
            return items.find((item: any) => {
                return item.title.replace(' ', '-') === slug;
            });
        }));
    }


    /*
      ---------------------------------------------
      ---------------  Wish List  -----------------
      ---------------------------------------------
    */

    // Get Wishlist Items
    public get wishlistItems(): Observable<Product[]> {
        const itemsStream = new Observable(observer => {
            observer.next(state.wishlist);
            observer.complete();
        });
        return <Observable<Product[]>> itemsStream;
    }

    // Add to Wishlist
    public addToWishlist(product): any {
        const wishlistItem = state.wishlist.find(item => item.id === product.id);
        if (!wishlistItem) {
            state.wishlist.push({
                ...product
            });
        }
        this.toastrService.success('El producto ha sido agregado en la lista de deseos.');
        localStorage.setItem('wishlistItems', JSON.stringify(state.wishlist));
        return true;
    }

    // Remove Wishlist items
    public removeWishlistItem(product: Product): any {
        const index = state.wishlist.indexOf(product);
        state.wishlist.splice(index, 1);
        localStorage.setItem('wishlistItems', JSON.stringify(state.wishlist));
        return true;
    }

    /*
      ---------------------------------------------
      -------------  Compare Product  -------------
      ---------------------------------------------
    */

    // Get Compare Items
    public get compareItems(): Observable<Product[]> {
        const itemsStream = new Observable(observer => {
            observer.next(state.compare);
            observer.complete();
        });
        return <Observable<Product[]>> itemsStream;
    }

    // Add to Compare
    public addToCompare(product): any {
        const compareItem = state.compare.find(item => item.id === product.id);
        if (!compareItem) {
            state.compare.push({
                ...product
            });
        }
        this.toastrService.success('Product has been added in compare.');
        localStorage.setItem('compareItems', JSON.stringify(state.compare));
        return true;
    }

    // Remove Compare items
    public removeCompareItem(product: Product): any {
        const index = state.compare.indexOf(product);
        state.compare.splice(index, 1);
        localStorage.setItem('compareItems', JSON.stringify(state.compare));
        return true;
    }

    /*
      ---------------------------------------------
      -----------------  Cart  --------------------
      ---------------------------------------------
    */

    // Get Cart Items
    public get cartItems(): Observable<Product[]> {
        const itemsStream = new Observable(observer => {
            observer.next(state.cart);
            observer.complete();
        });
        return <Observable<Product[]>> itemsStream;
    }

    // Add to Cart
    public addToCart(product): any {
        const cartItem = state.cart.find(item => item.id === product.id);
        const qty = product.quantity ? product.quantity : 1;
        const items = cartItem ? cartItem : product;
        const stock = this.calculateStockCounts(items, cartItem ? qty : 0);

        if (!stock) {
            return false;
        }

        if (cartItem) {
            cartItem.quantity += qty;
        } else {
            state.cart.push({
                ...product,
                quantity: qty
            });
        }

        this.OpenCart = true; // If we use cart variation modal
        this.toastrService.success('Producto agregado al carrito.');
        localStorage.setItem('cartItems', JSON.stringify(state.cart));
        return true;
    }

    // Update Cart Quantity
    public updateCartQuantity(product: Product, quantity: number, preciodescuento?: any): Product | boolean {
        return state.cart.find((items, index) => {
            if (items.id === product.id) {
                const qty = state.cart[index].quantity + quantity;
                const stock = this.calculateStockCounts(state.cart[index], quantity);
                if (qty !== 0 && stock) {
                    let precio = Number(localStorage.getItem('numprecio') || '0');
                    if (precio < 2) {
                        const descuento = preciodescuento.filter(p => (p['rangoinicio'] <= qty) && (qty <= p['rangofin']));
                        precio = descuento.length > 0 ? descuento[0]['precio'] : 1;
                    }
                    state.cart[index].quantity = qty;
                    state.cart[index].price = state.cart[index]['precio' + precio];
                }
                localStorage.setItem('cartItems', JSON.stringify(state.cart));
                return true;
            }
        });
    }

    // Update Cart Quantity
    public updatePrice(product: Product, preciodescuento?: any): Product | boolean {
        return state.cart.find((items, index) => {
            if (items.id === product.id) {
                const qty = Number(state.cart[index].quantity);
                state.cart[index].quantity = qty;
                const stock = this.calculateStockCounts(state.cart[index], 0);
                if (qty !== 0 && stock) {
                    let precio = Number(localStorage.getItem('numprecio') || '0');
                    console.log('update precio: ', precio);
                    if (precio < 2) {
                        const descuento = preciodescuento.filter(p => (p['rangoinicio'] <= qty) && (qty <= p['rangofin']));
                        precio = descuento.length > 0 ? descuento[0]['precio'] : 1;
                    }
                    state.cart[index].quantity = qty;
                    state.cart[index].price = state.cart[index]['precio' + precio];
                }
                localStorage.setItem('cartItems', JSON.stringify(state.cart));
                return true;
            }
        });
    }

    // Calculate Stock Counts
    public calculateStockCounts(product, quantity) {
        const qty = product.quantity + quantity;
        const stock = product.stock;
        if (stock < qty || stock == 0) {
            this.toastrService.error('No puede agregar más elementos de los disponibles. En stock ' + stock + ' items.');
            return false;
        }
        return true;
    }

    // Remove Cart items
    public removeCartItem(product: Product): any {
        const index = state.cart.indexOf(product);
        state.cart.splice(index, 1);
        localStorage.setItem('cartItems', JSON.stringify(state.cart));
        return true;
    }

    // Total amount
    public cartTotalAmount(): Observable<number> {
        return this.cartItems.pipe(map((product: Product[]) => {
            return product.reduce((prev, curr: Product) => {
                let price = curr.price;
                if (curr.discount) {
                    price = curr.price - (curr.price * curr.discount / 100);
                }
                return (prev + price * curr.quantity) * this.Currency.price;
            }, 0);
        }));
    }

    /*
      ---------------------------------------------
      ------------  Filter Product  ---------------
      ---------------------------------------------
    */

    // Get Product Filter
    public filterProducts(filter: any): Observable<Product[]> {
        return this.products.pipe(map(product =>
            product.filter((item: Product) => {
                if (!filter.length) {
                    return true;
                }
                const Tags = filter.some((prev) => { // Match Tags
                    if (item.tags) {
                        if (item.tags.includes(prev)) {
                            return prev;
                        }
                    }
                });
                return Tags;
            })
        ));
    }

    /*
      ---------------------------------------------
      ------------- Product Pagination  -----------
      ---------------------------------------------
    */
    public getPager(totalItems: number, currentPage: number = 1, pageSize: number = 16) {
        // calculate total pages
        let totalPages = Math.ceil(totalItems / pageSize);

        // Paginate Range
        let paginateRange = 3;

        // ensure current page isn't out of range
        if (currentPage < 1) {
            currentPage = 1;
        } else if (currentPage > totalPages) {
            currentPage = totalPages;
        }

        let startPage: number, endPage: number;
        if (totalPages <= 5) {
            startPage = 1;
            endPage = totalPages;
        } else if (currentPage < paginateRange - 1) {
            startPage = 1;
            endPage = startPage + paginateRange - 1;
        } else {
            startPage = currentPage - 1;
            endPage = currentPage + 1;
        }

        // calculate start and end item indexes
        let startIndex = (currentPage - 1) * pageSize;
        let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

        // create an array of pages to ng-repeat in the pager control
        let pages = Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i);

        // return object with all pager properties required by the view
        return {
            totalItems: totalItems,
            currentPage: currentPage,
            pageSize: pageSize,
            totalPages: totalPages,
            startPage: startPage,
            endPage: endPage,
            startIndex: startIndex,
            endIndex: endIndex,
            pages: pages
        };
    }

    /////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////// Nuevos procesos ////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////

    public listproducts(params?: any): Observable<Product[]> {
        let httpParams = new HttpParams();
        if (params && params.length > 0) {
            for (let i = 0; i < params.length; i++) {
                httpParams = httpParams.append(params[i].param, params[i].value);
            }
        }
        return this.http.get<Product[]>(this.urlmatriz, {params: httpParams}).pipe(map(data => data));
    }

    public listproductsmasvendidos(params?: any): Observable<Product[]> {
        let httpParams = new HttpParams();
        if (params && params.length > 0) {
            for (let i = 0; i < params.length; i++) {
                httpParams = httpParams.append(params[i].param, params[i].value);
            }
        }
        return this.http.get<Product[]>(this.urlmasvendidos, {params: httpParams}).pipe(map(data => data));
    }

    public listpreciodescuento(params?: any): Observable<Product[]> {
        let httpParams = new HttpParams();
        if (params && params.length > 0) {
            for (let i = 0; i < params.length; i++) {
                httpParams = httpParams.append(params[i].param, params[i].value);
            }
        }
        return this.http.get<Product[]>(this.urlpreciodescuento, {params: httpParams}).pipe(map(data => data));
    }

    obtenerDatosPaginados(url: string, page: Page, currentPage?): Observable<any> {
        const startIndex = (page.page_number * page.size) - 20;
        // preparar parametros http
        let httpParams = new HttpParams()
            .set('limit', String(page.size))
            .set('offset', String(startIndex))
            .set('ordering', page.ordering())
            .set('search', page.filter);
        const params = page.params ? page.params : [];
        if (params.length) {
            for (let i = 0; i < page.params.length; i++) {
                httpParams = httpParams.append(page.params[i].param, page.params[i].value);
            }
        }
        return this.http.get(`${this.urlmatriz}?opcion=dynamic`, {
            params: httpParams
        }).pipe(map((data) => this.procesarDatosPaginados(page, data, currentPage)));
    }


    // public getPager(totalItems: number, currentPage: number = 1, pageSize: number = 16) {
    procesarDatosPaginados(page: Page, resp: any, currentPage) {
        let totalPages = Math.ceil(resp['count'] / page.size);
        // Paginate Range
        let pageSize = page.size;
        let totalItems = resp['count'];
        // currentPage=numero actual
        // ensure current page isn't out of range
        if (currentPage < 1) {
            currentPage = 1;
        } else if (currentPage > totalPages) {
            currentPage = totalPages;
        }
        let paginateRange = (Math.trunc(currentPage / 4) + 1) * 4;
        // "totalPages"= 897
        // "currentPage"= 10
        let startPage: number, endPage: number;
        if (totalPages <= 5) {
            startPage = 1;
            endPage = totalPages;
        } else if (currentPage < paginateRange) {
            if (paginateRange > 8) {
                startPage = paginateRange - 4 - 1;
                endPage = startPage + 4 + 1;
            } else {
                startPage = paginateRange - 4 + 1;
                endPage = startPage + 4 - 1;
            }
        } else {
            startPage = currentPage - 1;
            endPage = currentPage + 4 + 1;
        }
        // startPage = 9
        // endPage = 11
        if (endPage > totalPages) {
            endPage = totalPages;
        }
        // calculate start and end item indexes
        let startIndex = (currentPage - 1) * pageSize;
        let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);
        let pages = Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i);
        // create an array of pages to ng-repeat in the pager control
        page.count = resp['count'];
        page.total_pages = page.count / page.size;

        return {
            totalItems: totalItems,
            currentPage: currentPage,
            pageSize: pageSize,
            totalPages: totalPages,
            startPage: startPage,
            endPage: endPage,
            startIndex: startIndex,
            endIndex: endIndex,
            pages: pages,
            res: resp['results']
        };
    }

    // Get Products By Slug
    public obtenerProducto(id: string): Observable<Product> {
        return this.http.get(`${this.urlmatriz}${id}/`).pipe(map(data => data));
    }

    public setTransporte(valor: string) {
        localStorage.setItem('transporte', valor);
    }

    public getTranporte() {
        return localStorage['transporte'] || 0;
    }

    public setdireccionentrega(valor: string) {
        localStorage.setItem('direccionentrega', valor);
    }

    public getdireccionentrega() {
        return localStorage['direccionentrega'] || '';
    }
}
