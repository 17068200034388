import {Component, OnInit} from '@angular/core';
import {AppConfig} from '../../../app.config';

@Component({
    selector: 'app-layout-box',
    templateUrl: './layout-box.component.html',
    styleUrls: ['./layout-box.component.scss']
})
export class LayoutBoxComponent implements OnInit {
    public layoutsidebar = false;
    public activeItem: string;
    public today: number = Date.now();
    public phone = '+593969044000';
    public title = 'Necesito información acerca de un producto ofertado en la página web';

    constructor(public appConfig: AppConfig) {
    }

    ngOnInit(): void {
    }

    // Is active
    isActive(menuItem) {
        return this.activeItem === menuItem;
    }

    // Sidebar Toggle
    layoutSidebarToggle() {
        this.layoutsidebar = !this.layoutsidebar;
    }

    // collapse
    opensettingcontent(val) {
        if (this.activeItem === val) {
            this.activeItem = '';
        } else {
            this.activeItem = val;
        }
    }

    // Layout Type
    customizeLayoutType(val) {
        if (val === 'rtl') {
            document.body.classList.remove('ltr');
            document.body.classList.add('rtl');
        } else {
            document.body.classList.remove('rtl');
            document.body.classList.add('ltr');
        }
    }

    // Set Theme color
    customizeThemeColor(event) {
        console.log(event.target.value);
        document.documentElement.style.setProperty('--theme-deafult', event.target.value);
    }

    customizeLayoutDark() {
        document.body.classList.toggle('dark');
    }

    chatWatsapp() {
    }

}
