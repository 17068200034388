import {
    Component, OnInit, OnDestroy, ViewChild, TemplateRef, PLATFORM_ID, Inject
} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import {Router} from '@angular/router';
import {GrabarpedidoService} from '../../../services/grabarpedido.service';
import * as $ from 'jquery';
import {ToastrService} from 'ngx-toastr';
import {AuthService} from '../../../services/auth.service';
import {Empresa} from '../../../classes/empresa';
import {LoginService} from '../../../services/login.service';

@Component({
    selector: 'app-payment',
    templateUrl: './payment.component.html',
    styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit, OnDestroy {

    @ViewChild('paymentView', {static: false}) QuickView: TemplateRef<any>;
    public empresa: Empresa = new Empresa();
    public closeResult: string;
    public modalOpen = false;
    public checkoutid;
    public datos = {};
    public cargando = true;
    public tipo = '';

    constructor(@Inject(PLATFORM_ID) private platformId: Object, private grabarpedidoService: GrabarpedidoService,
                private router: Router, private modalService: NgbModal,
                private toastrService: ToastrService, private authService: AuthService, private loginService: LoginService) {
    }

    ngOnInit(): void {
        this.loginService.getEmpresa().subscribe(res => {
            this.empresa = res ? res : this.empresa;
        });
    }

    async openModal(datos) {
        this.modalOpen = true;
        this.datos = datos;
        this.tipo = this.datos['tipopago'];
        if (this.tipo === 'deposito') {
            this.cargando = false;
        } else {
            this.enviarPedido();
        }
        if (isPlatformBrowser(this.platformId)) { // For SSR
            this.modalService.open(this.QuickView, {
                ariaLabelledBy: 'modal-basic-title',
                centered: true,
                windowClass: 'Quickview'
            }).result.then((result) => {
                `Result ${result}`;
            }, (reason) => {
                this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            });
        }
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }


    ngOnDestroy() {
        if (this.modalOpen) {
            this.modalService.dismissAll();
        }
    }


    enviarPedido() {
        // this.addWidget('https://ajax.googleapis.com/ajax/libs/jquery/2.1.4/jquery.min.js');
        // const url = 'https://pay.payphonetodoesposible.com/api/button/js?appId=CMTKSLGbBEyrayBkitl2ng';
        // this.addWidget(url);
        this.addWidgetContent();
        this.cargando = false;
        // this.grabarpedidoService.pagar(this.datos).subscribe((res: any) => {
        //     if (res['status'] === 201) {
        //         const result = res['body'];
        //         const url = 'https://oppwa.com/v1/paymentWidgets.js?checkoutId=' + result['id'];
        //         this.addWidget(url);
        //         this.addWidget('https://ajax.googleapis.com/ajax/libs/jquery/2.1.4/jquery.min.js');
        //         this.addWidgetContent();
        //         this.checkoutid = result['id'];
        //         this.cargando = false;
        //     }
        // }, error => {
        //     console.log(error);
        //     if (error.error['detail'] === 'Signature has expired.') {
        //         this.toastrService.error('Su sesión ha caducado, vuelva a iniciar sesión');
        //         this.authService.logout();
        //         this.cargando = false;
        //         this.modalService.dismissAll();
        //     }
        //     console.log(error.error, 'error ??');
        // });
    }

    addWidget(url: string) {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = url;
        document.body.appendChild(script);
    }

    grabar() {
        this.grabarpedidoService.grabar(this.datos).subscribe((res: any) => {
            if (res['status'] === 201) {
                const result = res['body'];
                console.log(result);
                const transaccion = JSON.stringify(result['transaccion']);
                localStorage.setItem('transaccion', transaccion);
                this.router.navigate(['/shop/checkout/success/' + result['transaccion']['transid']]);
            }
        }, error => {
            if (error.error['detail'] === 'Signature has expired.') {
                this.toastrService.error('Su sesión ha caducado, vuelva a iniciar sesión');
                this.modalService.dismissAll();
                this.authService.logout();

            }
        });
    }

    addWidgetContent() {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        document.body.appendChild(script);
    }
}
