import {Component, OnInit, Input} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {AppConfig} from '../../../app.config';

@Component({
    selector: 'app-breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {

    @Input() title: string;
    @Input() breadcrumb: string;
    categoria = '';
    public urlshop = '';

    constructor(protected route: ActivatedRoute, protected appConfig: AppConfig) {
        this.urlshop = this.appConfig.getLayoutShop();
        this.route.queryParams.subscribe(params => {
            this.categoria =  params.category ? params.category : '';
        });
    }

    ngOnInit(): void {
    }

}
