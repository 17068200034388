<header class="sticky" [ngClass]="class" [class.fixed]="stick && sticky">
    <div class="mobile-fix-option"></div>
    <div class="top-header" *ngIf="topbar">
        <div class="container">
            <div class="row">
                <div class="col-lg-7">
                    <div class="header-contact">
                        <ul>
                            <li>{{ 'topbar-title' | translate }} {{empresa.nombreempresa}}</li>
                            <li>
                                <i class="fa fa-phone" aria-hidden="true"></i>{{ 'call-us' | translate }}
                                <a>{{empresa.telefono1}} - {{empresa.telefono2}} </a>
                            </li>
                            <li>
                                <a target="_blank" href="https://www.facebook.com/sodilibro"><i
                                        class="fa fa-facebook"
                                        aria-hidden="true"></i></a>
                                <a target="_blank" href="https://www.instagram.com/sodilibro/"><i
                                        class="fa fa-instagram"
                                        aria-hidden="true"></i></a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-5 text-right">
                    <ul class="header-dropdown">
                        <li>
                            <a href="#">{{nameUser}}</a>
                        </li>
<!--                        <li class="compare">-->
<!--                            <a [routerLink]="['/shop/compare']">-->
<!--                                <i class="fa fa-random" aria-hidden="true"></i>{{ 'compare' | translate}}-->
<!--                            </a>-->
<!--                        </li>-->
                        <li class="mobile-wishlist">
                            <a [routerLink]="['/shop/wishlist']">
                                <i class="fa fa-heart" aria-hidden="true"></i> {{ 'wishlist' | translate}}
                            </a>
                        </li>
                        <li class="mobile-account">
                            <a href="javascript:void(0)" (click)="login()" *ngIf="nameUser">
                                <i class="fa fa-user" aria-hidden="true"></i> {{ 'myaccount' | translate}}
                            </a>
                            <a href="javascript:void(0)" (click)="login()" *ngIf="!nameUser">
                                <i class="fa fa-user" aria-hidden="true"></i> {{ 'login' | translate}} / {{'register' | translate}}
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <div class="main-menu border-section border-top-0">
                    <div class="brand-logo layout2-logo">
                        <a [routerLink]="['/home']">
                            <img [src]="themeLogo" class="img-fluid" alt="logo" style="width: 200px; height: 62px">
                        </a>
                    </div>
                    <div>
                        <form class="form_search" role="form">
                            <input id="query search-autocomplete" [formControl]="buscarCrl" placeholder="{{'search' | translate}}"
                                   class="nav-search nav-search-field" aria-expanded="true" (keydown.enter)="buscar()">
                            <button type="button" name="nav-submit-button" class="btn-search" (click)="buscar()">
                                <i class="ti-search"></i>
                            </button>
                        </form>
                    </div>
                    <div class="menu-right pull-right">
                        <app-settings></app-settings>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row ">
            <div class="col-lg-12">
                <div class="main-nav-center  border-section border-top-0">
                    <app-menu></app-menu>
                </div>
            </div>
        </div>
    </div>
</header>
<!-- header end -->
