import {Component, Injectable, PLATFORM_ID, Inject, OnInit} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';
import {LoadingBarService} from '@ngx-loading-bar/core';
import {map, delay, withLatestFrom} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {EmpresaService} from './shared/services/empresa.service';
import {LoginService} from './shared/services/login.service';
import {Idle, DEFAULT_INTERRUPTSOURCES} from '@ng-idle/core';
import {Keepalive} from '@ng-idle/keepalive';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    // For Progressbar
    idleState = 'Not started.';
    timedOut = false;
    lastPing?: Date = null;
    loaders = this.loader.progress$.pipe(
        delay(1000),
        withLatestFrom(this.loader.progress$),
        map(v => v[1]),
    );

    // tslint:disable-next-line:ban-types
    constructor(@Inject(PLATFORM_ID) private platformId: Object,
                private loader: LoadingBarService, translate: TranslateService,
                protected empresaService: EmpresaService,
                protected authService: LoginService,
                private idle: Idle, private keepalive: Keepalive) {
        // translate.setDefaultLang('es');
        // translate.use('es');
        if (isPlatformBrowser(this.platformId)) {
            translate.setDefaultLang('es');
            translate.use('es');
            // translate.addLangs(['en', 'fr', 'es']);
        }
    }

    tiempo_espera(tiempo: number) {
        tiempo *= 60
        this.idle.setIdle(tiempo);
        this.idle.setTimeout(30);
        this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
        this.idle.onIdleEnd.subscribe(() => this.idleState = 'Ya no está inactivo.');
        this.idle.onTimeout.subscribe(() => {
            this.authService.logout();
            this.idleState = 'Caducado!';
            this.timedOut = true;
        });
        this.idle.onIdleStart.subscribe(() => this.idleState = 'Te has quedado inactivo!');
        this.idle.onTimeoutWarning.subscribe((countdown) => {
            this.idleState = 'Tendrás tiempo de espera en ' + countdown + ' secundos!';
            console.log('Tendrás tiempo de espera en ' + countdown + ' secundos!');
        });
        this.keepalive.interval(30);
        this.keepalive.onPing.subscribe(() => this.lastPing = new Date());
        this.reset();
    }

    reset() {
        this.idle.watch();
        this.idleState = 'Started.';
        this.timedOut = false;
    }

    async ngOnInit() {
        const empresa = await this.empresaService.getEmpresaApi().toPromise();
        localStorage.setItem('empresa', empresa);
        this.authService.setEmpresa(empresa);
        this.tiempo_espera(empresa['tiempoespera']);
    }
}
