<!-- Add to cart modal popup start-->
<ng-template #cartModal let-modal>
    <div class="modal-body">
        <div>
            <div class="row">
                <div class="col-12">
                    <div class="modal-bg addtocart">
                        <button type="button" id="close-cart-modal" class="close"
                                (click)="modal.dismiss('Cross click')">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <div class="media">
                            <a [routerLink]="[urlproduct, product.id]" [queryParams]="parametros">
                                <img class="img-fluid pro-img" [defaultImage]="'assets/images/product/placeholder.jpg'"
                                     [lazyLoad]="product.images[0].src" [alt]="product.images[0].alt">
                            </a>
                            <div class="media-body align-self-center text-center">
                                <a [routerLink]="[urlproduct, product.id]" [queryParams]="parametros">
                                    <h6>
                                        <i class="fa fa-check"></i>Item
                                        <span>{{ product.title }}</span>
                                        <span> {{'Successfully-added-to-your-Cart' | translate}}</span>
                                    </h6>
                                </a>
                                <div class="buttons">
                                    <a [routerLink]="['/shop/cart']"
                                       class="view-cart btn btn-solid">{{'Your-cart' | translate}}</a>
                                    <a [routerLink]="['/shop/checkout']"
                                       class="checkout btn btn-solid">{{'check-out' | translate}}</a>
                                    <a [routerLink]="[urlshop]"
                                       class="continue btn btn-solid">{{'Continue-shopping' | translate}}</a>
                                </div>
                            </div>
                        </div>

                        <div class="product-section">
                            <div class="col-12 product-upsell text-center">
                                <h4>{{'Customers-who-bought-this-item-also' | translate}}</h4>
                            </div>
                            <div class="row">
                                <ng-container *ngFor="let product of products">
                                    <div class="product-box col-sm-3 col-6">
                                        <div class="img-wrapper">
                                            <div class="front">
                                                <a [routerLink]="[urlproduct, product.id]" [queryParams]="parametros">
                                                    <img [defaultImage]="'assets/images/product/placeholder.jpg'"
                                                         [lazyLoad]="product.images[0].src"
                                                         [alt]="product.images[0].alt" class="img-fluid mb-1" alt="">
                                                </a>
                                            </div>
                                            <div class="product-detail">
                                                <h6 class="mt-0">
                                                    <a [routerLink]="[urlproduct, product.id]" [queryParams]="parametros"><span>{{ product.title }}</span></a>
                                                </h6>
                                                <h4 *ngIf="!product['bandiva']"><span>{{ product.price * currency?.price | discount:product | currency:currency?.currency:'US$ ' }}</span></h4>
                                                <h4 *ngIf="product['bandiva']"><span>{{ product.price * currency?.price * 1.12 | currency:currency?.currency:'US$ ' }}</span></h4>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<!-- Add to cart modal popup end-->
