import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/observable';
import {Categorias} from '../classes/categorias';
import {AppConfig} from '../../app.config';


@Injectable({
    providedIn: 'root'
})
export class CategoriasService {
    public BASE_URL: string;

    constructor(private http: HttpClient, protected config: AppConfig) {
        this.BASE_URL = this.config.getEnv('API_URL');
    }

    getCategoriasApi(): Observable<Categorias[]> {
        return this.http.get<Categorias[]>(this.BASE_URL + '/api/shopcart/categorias/');
    }
}
