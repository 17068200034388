import {
    Component, OnInit, OnDestroy, ViewChild, TemplateRef, Input, AfterViewInit,
    Injectable, PLATFORM_ID, Inject
} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';
import {NgbModal, ModalDismissReasons, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ProductService} from '../../../services/product.service';
import {Product} from '../../../classes/product';
import {AppConfig} from '../../../../app.config';
import {ActivatedRoute} from '@angular/router';

@Component({
    selector: 'app-cart-modal',
    templateUrl: './cart-modal.component.html',
    styleUrls: ['./cart-modal.component.scss']
})
export class CartModalComponent implements OnInit, AfterViewInit, OnDestroy {

    @Input() product: Product;
    @Input() currency: any;

    @ViewChild('cartModal', {static: false}) CartModal: TemplateRef<any>;

    public closeResult: string;
    public modalOpen: boolean = false;
    public products: any[] = [];
    public urlproduct = '';
    public urlshop = '';
    public parametros = null;

    constructor(@Inject(PLATFORM_ID) private platformId: Object,
                private modalService: NgbModal,
                private route: ActivatedRoute,
                private productService: ProductService,
                protected appConfig: AppConfig) {
        this.urlproduct = this.appConfig.getLayoutProduct();
        this.urlshop = this.appConfig.getLayoutShop();
        this.route.queryParams.subscribe(params => {
            this.parametros = params;
        });
    }

    ngOnInit(): void {
    }

    ngAfterViewInit(): void {
    }

    async openModal(product) {
        const res = await this.productService.listproducts([{param: 'desc4', value: product.category}, {
            param: 'desc4',
            value: product.category
        }, {
            param: 'limit',
            value: 4
        }]).toPromise();
        this.products = res['results'];
        const status = await this.productService.addToCart(product);
        if (status) {
            this.modalOpen = true;
            if (isPlatformBrowser(this.platformId)) { // For SSR
                this.modalService.open(this.CartModal, {
                    size: 'lg',
                    ariaLabelledBy: 'Cart-Modal',
                    centered: true,
                    windowClass: 'theme-modal cart-modal CartModal'
                }).result.then((result) => {
                    `Result ${result}`;
                }, (reason) => {
                    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
                });
            }
        }
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    ngOnDestroy() {
        if (this.modalOpen) {
            this.modalService.dismissAll();
        }
    }

}
