import {Component, OnInit, Input, HostListener} from '@angular/core';
import {Empresa} from '../../classes/empresa';
import {LoginService} from '../../services/login.service';
import {Router} from '@angular/router';
import {FormControl} from '@angular/forms';
import {AppConfig} from '../../../app.config';

@Component({
    selector: 'app-header-base',
    templateUrl: './header-base.component.html',
})
export class HeaderBaseComponent implements OnInit {
    @Input() class; // Default Logo
    @Input() themeLogo = 'assets/images/logo.png'; // Default Logo
    @Input() topbar = true; // Default True
    @Input() sticky = false; // Default false
    public stick = true;
    public nameUser = '';
    public empresa: Empresa = new Empresa();
    public buscarCrl: FormControl = new FormControl('');
    public urlshop = '';
    constructor(protected authService: LoginService, protected router: Router, protected appConfig: AppConfig) {
        this.urlshop = this.appConfig.getLayoutShop();
    }

    ngOnInit(): void {
        this.nameUser = this.authService.getNombre();
        if (this.authService.getNombre() != null) {
            // const nom = this.nameUser.split(' ');
            this.nameUser = 'Bienvenido '.concat(this.nameUser);
        } else {
            this.nameUser = '';
        }
        this.authService.getEmpresa().subscribe(res => {
            this.empresa = res ? res : this.empresa;
        });
    }

    login() {
        const token = localStorage.getItem('jwt_token');
        if (token !== null) {
            this.router.navigate(['/pages/dashboard/profile']);
        } else {
            this.router.navigate(['/pages/login']);
        }
    }

    onclickLogout() {
        this.authService.logout();
    }

    // @HostListener Decorator
    @HostListener('window:scroll', [])
    onWindowScroll() {
        const number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
        // tslint:disable-next-line:indent
        if (number >= 300 && window.innerWidth > 400) {
            this.stick = true;
        } else {
            this.stick = false;
        }
    }

    buscar() {
        const search = this.buscarCrl.value;
        this.router.navigate([this.urlshop], {queryParams: {buscar: search}});
    }

}
