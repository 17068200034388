<div>
    <!-- theme setting -->
<!--    <a href="javascript:void(0)">-->
<!--        <div class="setting-sidebar" id="setting-icon" (click)="layoutSidebarToggle()">-->
<!--            <div>-->
<!--                <i class="fa fa-cog" aria-hidden="true"></i>-->
<!--            </div>-->
<!--        </div>-->
<!--    </a>-->
<!--    <div id="setting_box" class="setting-box" [class.opensetting]="layoutsidebar">-->
<!--        <a href="javascript:void(0)" class="overlay" (click)="layoutSidebarToggle()"></a>-->
<!--        <div class="setting_box_body">-->
<!--            <div>-->
<!--                <div class="sidebar-back text-left" (click)="layoutSidebarToggle()">-->
<!--                    <i class="fa fa-angle-left pr-2" aria-hidden="true"></i> {{'back' | translate}}-->
<!--                </div>-->
<!--            </div>-->
<!--            <div class="setting-body">-->
<!--                <div class="setting-title" (click)="opensettingcontent('layout')">-->
<!--                    <h4>-->
<!--                        layout-->
<!--                        <span class="according-menu"></span>-->
<!--                    </h4>-->
<!--                </div>-->
<!--                <div class="setting-contant" [class.opensubmenu]="isActive('layout')">-->
<!--                    <div class="row demo-section">-->

<!--                        <div class="col-sm-6 col-12 text-center demo-effects">-->
<!--                            <div class="set-position">-->
<!--                                <div class="layout-container demo1"></div>-->
<!--                                <div class="demo-text">-->
<!--                                    <h4>Fashion</h4>-->
<!--                                    <div class="btn-group demo-btn" role="group" aria-label="Basic example">-->
<!--                                        <a [routerLink]="['/home']" class="btn new-tab-btn">-->
<!--                                            {{'preview' | translate}}-->
<!--                                        </a>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <div class="col-sm-6 col-12 text-center demo-effects">-->
<!--                            <div class="set-position">-->
<!--                                <div class="layout-container demo2"></div>-->
<!--                                <div class="demo-text">-->
<!--                                    <h4>Fashion 2</h4>-->
<!--                                    <div class="btn-group demo-btn" role="group" aria-label="Basic example">-->
<!--                                        <a [routerLink]="['/home-2']" class="btn new-tab-btn">-->
<!--                                            {{'preview' | translate}}-->
<!--                                        </a>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <div class="col-sm-6 col-12 text-center demo-effects">-->
<!--                            <div class="set-position">-->
<!--                                <div class="layout-container demo3"></div>-->
<!--                                <div class="demo-text">-->
<!--                                    <h4>Fashion 2</h4>-->
<!--                                    <div class="btn-group demo-btn" role="group" aria-label="Basic example">-->
<!--                                        <a [routerLink]="['/home-3']" class="btn new-tab-btn">-->
<!--                                            {{'preview' | translate}}-->
<!--                                        </a>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <div class="col-sm-6 col-12 text-center demo-effects">-->
<!--                            <div class="set-position">-->
<!--                                <div class="layout-container demo4"></div>-->
<!--                                <div class="demo-text">-->
<!--                                    <h4>Vegetable</h4>-->
<!--                                    <div class="btn-group demo-btn" role="group" aria-label="Basic example">-->
<!--                                        <a [routerLink]="['/home/vegetable']" class="btn new-tab-btn">-->
<!--                                            {{'preview' | translate}}-->
<!--                                        </a>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <div class="col-sm-6 col-12 text-center demo-effects">-->
<!--                            <div class="set-position">-->
<!--                                <div class="layout-container demo5"></div>-->
<!--                                <div class="demo-text">-->
<!--                                    <h4>Watch</h4>-->
<!--                                    <div class="btn-group demo-btn" role="group" aria-label="Basic example">-->
<!--                                        <a [routerLink]="['/home/watch']" class="btn new-tab-btn">-->
<!--                                            {{'preview' | translate}}-->
<!--                                        </a>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <div class="col-sm-6 col-12 text-center demo-effects">-->
<!--                            <div class="set-position">-->
<!--                                <div class="layout-container demo6"></div>-->
<!--                                <div class="demo-text">-->
<!--                                    <h4>Furniture</h4>-->
<!--                                    <div class="btn-group demo-btn" role="group" aria-label="Basic example">-->
<!--                                        <a [routerLink]="['/home/furniture']" class="btn new-tab-btn">-->
<!--                                            {{'preview' | translate}}-->
<!--                                        </a>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <div class="col-sm-6 col-12 text-center demo-effects">-->
<!--                            <div class="set-position">-->
<!--                                <div class="layout-container demo7"></div>-->
<!--                                <div class="demo-text">-->
<!--                                    <h4>Flower</h4>-->
<!--                                    <div class="btn-group demo-btn" role="group" aria-label="Basic example">-->
<!--                                        <a [routerLink]="['/home/flower']" class="btn new-tab-btn">-->
<!--                                            {{'preview' | translate}}-->
<!--                                        </a>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <div class="col-sm-6 col-12 text-center demo-effects">-->
<!--                            <div class="set-position">-->
<!--                                <div class="layout-container demo8"></div>-->
<!--                                <div class="demo-text">-->
<!--                                    <h4>Beauty</h4>-->
<!--                                    <div class="btn-group demo-btn" role="group" aria-label="Basic example">-->
<!--                                        <a [routerLink]="['/home/beauty']" class="btn new-tab-btn">-->
<!--                                            {{'preview' | translate}}-->
<!--                                        </a>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <div class="col-sm-6 col-12 text-center demo-effects">-->
<!--                            <div class="set-position">-->
<!--                                <div class="layout-container demo9"></div>-->
<!--                                <div class="demo-text">-->
<!--                                    <h4>Electronics</h4>-->
<!--                                    <div class="btn-group demo-btn" role="group" aria-label="Basic example">-->
<!--                                        <a [routerLink]="['/home/electronics']" class="btn new-tab-btn">-->
<!--                                            {{'preview' | translate}}-->
<!--                                        </a>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <div class="col-sm-6 col-12 text-center demo-effects">-->
<!--                            <div class="set-position">-->
<!--                                <div class="layout-container demo10"></div>-->
<!--                                <div class="demo-text">-->
<!--                                    <h4>Pets</h4>-->
<!--                                    <div class="btn-group demo-btn" role="group" aria-label="Basic example">-->
<!--                                        <a [routerLink]="['/home/pets']" class="btn new-tab-btn">-->
<!--                                            {{'preview' | translate}}-->
<!--                                        </a>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <div class="col-sm-6 col-12 text-center demo-effects">-->
<!--                            <div class="set-position">-->
<!--                                <div class="layout-container demo11"></div>-->
<!--                                <div class="demo-text">-->
<!--                                    <h4>Gym</h4>-->
<!--                                    <div class="btn-group demo-btn" role="group" aria-label="Basic example">-->
<!--                                        <a [routerLink]="['/home/gym']" class="btn new-tab-btn">-->
<!--                                            {{'preview' | translate}}-->
<!--                                        </a>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <div class="col-sm-6 col-12 text-center demo-effects">-->
<!--                            <div class="set-position">-->
<!--                                <div class="layout-container demo12"></div>-->
<!--                                <div class="demo-text">-->
<!--                                    <h4>Tools</h4>-->
<!--                                    <div class="btn-group demo-btn" role="group" aria-label="Basic example">-->
<!--                                        <a [routerLink]="['/home/tools']" class="btn new-tab-btn">-->
<!--                                            {{'preview' | translate}}-->
<!--                                        </a>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <div class="col-sm-6 col-12 text-center demo-effects">-->
<!--                            <div class="set-position">-->
<!--                                <div class="layout-container demo13"></div>-->
<!--                                <div class="demo-text">-->
<!--                                    <h4>Shoes</h4>-->
<!--                                    <div class="btn-group demo-btn" role="group" aria-label="Basic example">-->
<!--                                        <a [routerLink]="['/home/shoes']" class="btn new-tab-btn">-->
<!--                                            {{'preview' | translate}}-->
<!--                                        </a>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <div class="col-sm-6 col-12 text-center demo-effects">-->
<!--                            <div class="set-position">-->
<!--                                <div class="layout-container demo14"></div>-->
<!--                                <div class="demo-text">-->
<!--                                    <h4>Bags</h4>-->
<!--                                    <div class="btn-group demo-btn" role="group" aria-label="Basic example">-->
<!--                                        <a [routerLink]="['/home/bags']" class="btn new-tab-btn">-->
<!--                                            {{'preview' | translate}}-->
<!--                                        </a>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <div class="col-sm-6 col-12 text-center demo-effects mb-0">-->
<!--                            <div class="set-position">-->
<!--                                <div class="layout-container demo15"></div>-->
<!--                                <div class="demo-text">-->
<!--                                    <h4>Marijuana</h4>-->
<!--                                    <div class="btn-group demo-btn" role="group" aria-label="Basic example">-->
<!--                                        <a [routerLink]="['/home/marijuana']" class="btn new-tab-btn">-->
<!--                                            {{'preview' | translate}}-->
<!--                                        </a>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div class="setting-title" (click)="opensettingcontent('shop')">-->
<!--                    <h4>-->
<!--                        {{'shop' | translate}}-->
<!--                        <span class="according-menu"></span>-->
<!--                    </h4>-->
<!--                </div>-->
<!--                <div class="setting-contant" [class.opensubmenu]="isActive('shop')">-->
<!--                    <div class="row demo-section">-->
<!--                        <div class="col-sm-6 col-12 text-center demo-effects">-->
<!--                            <div class="set-position">-->
<!--                                <div class="layout-container shop1"></div>-->
<!--                                <div class="demo-text">-->
<!--                                    <h4>{{'left-sidebar' | translate}}</h4>-->
<!--                                    <div class="btn-group demo-btn" role="group" aria-label="Basic example">-->
<!--                                        <a href="javascript:void(0)" (click)="appConfig.setLayoutShop('left')"-->
<!--                                           class="btn new-tab-btn">{{'preview' | translate}}-->
<!--                                        </a>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <div class="col-sm-6 col-12 text-center demo-effects">-->
<!--                            <div class="set-position">-->
<!--                                <div class="layout-container shop2"></div>-->
<!--                                <div class="demo-text">-->
<!--                                    <h4>{{'right-sidebar' | translate}}</h4>-->
<!--                                    <div class="btn-group demo-btn" role="group" aria-label="Basic example">-->
<!--                                            <a href="javascript:void(0)" (click)="appConfig.setLayoutShop('right')"-->
<!--                                           class="btn new-tab-btn">{{'preview' | translate}}-->
<!--                                        </a>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <div class="col-sm-6 col-12 text-center demo-effects">-->
<!--                            <div class="set-position">-->
<!--                                <div class="layout-container shop3"></div>-->
<!--                                <div class="demo-text">-->
<!--                                    <h4>{{'no-sidebar' | translate}}</h4>-->
<!--                                    <div class="btn-group demo-btn" role="group" aria-label="Basic example">-->
<!--                                        <a href="javascript:void(0)" (click)="appConfig.setLayoutShop('no')"-->
<!--                                           class="btn new-tab-btn">{{'preview' | translate}}-->
<!--                                        </a>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div class="setting-title" (click)="opensettingcontent('product')">-->
<!--                    <h4>-->
<!--                        {{'products' | translate}}-->
<!--                        <span class="according-menu"></span>-->
<!--                    </h4>-->
<!--                </div>-->
<!--                <div class="setting-contant" [class.opensubmenu]="isActive('product')">-->
<!--                    <div class="row demo-section">-->
<!--                        <div class="col-sm-6 col-12 text-center demo-effects">-->
<!--                            <div class="set-position">-->
<!--                                <div class="layout-container product1"></div>-->
<!--                                <div class="demo-text">-->
<!--                                    <h4>{{'left-sidebar' | translate}}</h4>-->
<!--                                    <div class="btn-group demo-btn" role="group" aria-label="Basic example">-->
<!--                                        <a href="javascript:void(0)" (click)="appConfig.setProduct('left/sidebar/')"-->
<!--                                           class="btn new-tab-btn">-->
<!--                                            {{'preview' | translate}}-->
<!--                                        </a>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <div class="col-sm-6 col-12 text-center demo-effects">-->
<!--                            <div class="set-position">-->
<!--                                <div class="layout-container product2"></div>-->
<!--                                <div class="demo-text">-->
<!--                                    <h4>{{'right-sidebar' | translate}}</h4>-->
<!--                                    <div class="btn-group demo-btn" role="group" aria-label="Basic example">-->
<!--                                        <a href="javascript:void(0)" (click)="appConfig.setProduct('right/sidebar/')"-->
<!--                                           class="btn new-tab-btn">-->
<!--                                            {{'preview' | translate}}-->
<!--                                        </a>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <div class="col-sm-6 col-12 text-center demo-effects">-->
<!--                            <div class="set-position">-->
<!--                                <div class="layout-container product3"></div>-->
<!--                                <div class="demo-text">-->
<!--                                    <h4>{{'no-sidebar' | translate}}</h4>-->
<!--                                    <div class="btn-group demo-btn" role="group" aria-label="Basic example">-->
<!--                                        <a href="javascript:void(0)" (click)="appConfig.setProduct('no/sidebar/')"-->
<!--                                           class="btn new-tab-btn">-->
<!--                                            {{'preview' | translate}}-->
<!--                                        </a>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <div class="col-sm-6 col-12 text-center demo-effects">-->
<!--                            <div class="set-position">-->
<!--                                <div class="layout-container product4"></div>-->
<!--                                <div class="demo-text">-->
<!--                                    <h4>{{'three-column' | translate}}</h4>-->
<!--                                    <div class="btn-group demo-btn" role="group" aria-label="Basic example">-->
<!--                                        <a href="javascript:void(0)" (click)="appConfig.setProduct('three/column/')"-->
<!--                                           class="btn new-tab-btn">-->
<!--                                            {{'preview' | translate}}-->
<!--                                        </a>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <div class="col-sm-6 col-12 text-center demo-effects">-->
<!--                            <div class="set-position">-->
<!--                                <div class="layout-container product5"></div>-->
<!--                                <div class="demo-text">-->
<!--                                    <h4>{{'four-image' | translate}}</h4>-->
<!--                                    <div class="btn-group demo-btn" role="group" aria-label="Basic example">-->
<!--                                        <a href="javascript:void(0)" (click)="appConfig.setProduct('four/image/')"-->
<!--                                           class="btn new-tab-btn">-->
<!--                                            {{'preview' | translate}}-->
<!--                                        </a>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <div class="col-sm-6 col-12 text-center demo-effects">-->
<!--                            <div class="set-position">-->
<!--                                <div class="layout-container product6"></div>-->
<!--                                <div class="demo-text">-->
<!--                                    <h4>{{'bundle-product' | translate}}</h4>-->
<!--                                    <div class="btn-group demo-btn" role="group" aria-label="Basic example">-->
<!--                                        <a [routerLink]="['/shop/product/bundle/trim-dress']" class="btn new-tab-btn">-->
<!--                                            {{'preview' | translate}}-->
<!--                                        </a>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <div class="col-sm-6 col-12 text-center demo-effects">-->
<!--                            <div class="set-position">-->
<!--                                <div class="layout-container product7"></div>-->
<!--                                <div class="demo-text">-->
<!--                                    <h4>{{'image-outside' | translate}}</h4>-->
<!--                                    <div class="btn-group demo-btn" role="group" aria-label="Basic example">-->
<!--                                        <a [routerLink]="['/shop/product/image/outside/trim-dress']"-->
<!--                                           class="btn new-tab-btn">-->
<!--                                            {{'preview' | translate}}-->
<!--                                        </a>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div class="setting-title" (click)="opensettingcontent('color option')">-->
<!--                    <h4>-->
<!--                        color picker-->
<!--                        <span class="according-menu"></span>-->
<!--                    </h4>-->
<!--                </div>-->
<!--                <div class="setting-contant" [class.opensubmenu]="isActive('color option')">-->
<!--                    <ul class="color-box">-->
<!--                        <li>-->
<!--                            <input id="colorPicker1" type="color" value="#ff4c3b" name="Background"-->
<!--                                   (change)="customizeThemeColor($event)">-->
<!--                            <span>theme deafult color</span>-->
<!--                        </li>-->
<!--                    </ul>-->
<!--                </div>-->
<!--                <div class="setting-title" (click)="opensettingcontent('rtl')">-->
<!--                    <h4>-->
<!--                        RTL-->
<!--                        <span class="according-menu"></span>-->
<!--                    </h4>-->
<!--                </div>-->
<!--                <div class="setting-contant" [class.opensubmenu]="isActive('rtl')">-->
<!--                    <ul class="setting_buttons">-->
<!--                        <li id="ltr_btn">-->
<!--                            <a href="javascript:void(0)" (click)="customizeLayoutType('ltr')" class="btn setting_btn">LTR</a>-->
<!--                        </li>-->
<!--                        <li id="rtl_btn">-->
<!--                            <a href="javascript:void(0)" (click)="customizeLayoutType('rtl')" class="btn setting_btn">RTL</a>-->
<!--                        </li>-->
<!--                    </ul>-->
<!--                </div>-->
<!--                <div class="buy_btn">-->
<!--                    <a href="https://www.ecuasolutions.com/" target="_blank" class="btn btn-block purchase_btn">-->
<!--                        <i class="fa fa-shopping-cart" aria-hidden="true"></i> Desarrollado por:-->
<!--                        <i class="fa fa-copyright" aria-hidden="true"></i> Ecuasolutions {{ today | date:'y'}}-->
<!--                    </a>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
    <!-- theme setting -->
<!--    <div class="sidebar-btn dark-light-btn">-->
<!--        <div class="dark-light">-->
<!--            <div class="theme-layout-version" (click)="customizeLayoutDark()">Dark</div>-->
<!--        </div>-->
<!--    </div>-->
    <a href="https://api.whatsapp.com/send?phone={{phone}}&text={{title}}."
       class="float" target="_blank">
        <i class="fa fa-whatsapp my-float"></i>
    </a>
<!--    <jjva-ngx-whatsapp-button [ngx_whatsapp_phone]="phone" [ngx_whatsapp_text]="title"></jjva-ngx-whatsapp-button>-->
</div>
