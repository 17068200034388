import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Router} from '@angular/router';
import {map} from 'rxjs/operators';
import {ItemtiendaService} from './itemtienda.service';
import {LoginService} from './login.service';
import {AppConfig} from '../../app.config';


@Injectable({
    providedIn: 'root'
})
export class GrabarpedidoService {
    public BASE_URL: string;
    public tokenLogin: string;

    constructor(private http: HttpClient, private router: Router, public itemTiendaService: ItemtiendaService,
                private authService: LoginService, protected config: AppConfig) {
        this.BASE_URL = this.config.getEnv('API_URL');
    }


    grabar(datajson: any) {
        this.tokenLogin = this.authService.getToken();
        const url = `${this.BASE_URL}/api/shopcart/savepedido/`;
        const headers = new HttpHeaders({
            Authorization: 'JWT ' + this.tokenLogin
        });
        return this.http.post(url, datajson, {headers, observe: 'response'}).pipe(map(res => res));
    }

    pagar(datajson: any) {
        this.tokenLogin = this.authService.getToken();
        const url = `${this.BASE_URL}/api/shopcart/checkid/`;
        const headers = new HttpHeaders({
            Authorization: 'JWT ' + this.tokenLogin
        });
        return this.http.post(url, datajson, {headers, observe: 'response'}).pipe(map(res => res));
    }

    guardar(datajson: any) {
        this.tokenLogin = this.authService.getToken();
        const url = `${this.BASE_URL}/api/shopcart/savepedido/`;
        const headers = new HttpHeaders({
            Authorization: 'JWT ' + this.tokenLogin
        });
        return this.http.post(url, datajson, {headers, observe: 'response'}).pipe(map(res => res));
    }

    getestadopago(id: string, datos: any) {
        this.tokenLogin = this.authService.getToken();
        const url = `${this.BASE_URL}/api/shopcart/checkestado/?id=${id}`;
        const headers = new HttpHeaders({
            Authorization: 'JWT ' + this.tokenLogin
        });
        return this.http.post(url, datos, {headers, observe: 'response'}).pipe(map(res => res));
    }
}
