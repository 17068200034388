<!--footer section -->
<footer [class]="class">
    <!--    <div class="light-layout" *ngIf="newsletter">-->
    <!--        <div class="container">-->
    <!--            <section class="small-section border-section border-top-0">-->
    <!--                <div class="row">-->
    <!--                    <div class="col-lg-6">-->
    <!--                        <div class="subscribe">-->
    <!--                            <div>-->
    <!--                                <h4>{{ 'know' | translate}}</h4>-->
    <!--                                <p>{{ 'description-know' | translate}}</p>-->
    <!--                            </div>-->
    <!--                        </div>-->
    <!--                    </div>-->
    <!--                    <div class="col-lg-6">-->
    <!--                        <form class="form-inline subscribe-form"-->
    <!--                              action="https://pixelstrap.us19.list-manage.com/subscribe/post?u=5a128856334b598b395f1fc9b&amp;id=082f74cbda"-->
    <!--                              target="_blank" method="post">-->
    <!--                            <div class="form-group mx-sm-3">-->
    <!--                                <input type="email" #name name="EMAIL" id="mce-EMAIL" class="form-control"-->
    <!--                                       placeholder="{{ 'enter-your-mail' | translate}}">-->
    <!--                            </div>-->
    <!--                            <button type="submit" class="btn btn-solid">{{ 'subscribe' | translate}}</button>-->
    <!--                        </form>-->
    <!--                    </div>-->
    <!--                </div>-->
    <!--            </section>-->
    <!--        </div>-->
    <!--    </div>-->
    <section class="section-b-space light-layout">
        <div class="container">
            <div class="row footer-theme partition-f">
                <div class="col-lg-4 col-md-6">
                    <!--div class="footer-title footer-mobile-title">
                        <h4>about</h4>
                    </div-->
                    <div class="footer-contant">
                        <div class="footer-logo">
                            <img [src]="themeLogo" alt="logo" style="width: 100px">
                        </div>
                        <p>Somos pioneros en la venta de libros</p>
                        <div class="footer-social">
                            <ul>
                                <li>
                                    <a target="_blank" href="https://www.facebook.com/sodilibro"><i
                                            class="fa fa-facebook"
                                            aria-hidden="true"></i></a>
                                </li>
                                <li>
                                    <a target="_blank" href="https://www.instagram.com/sodilibro/"><i
                                            class="fa fa-instagram"
                                            aria-hidden="true"></i></a>
                                </li>
                                <li>
                                    <a target="_blank" href="https://twitter.com/Sodilibro"><i
                                            class="fa fa-twitter"
                                            aria-hidden="true"></i></a>
                                </li>

                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col offset-xl-1">
                    <div class="sub-title">
                        <div class="footer-title">
                            <h4>{{'category' | translate}}</h4>
                        </div>

                        <div class="collection-collapse-block border-0" [class.open]="true">
                            <div class="collection-collapse-block-content">
                                <div class="collection-brand-filter">
                                    <div class="footer-contant">
                                        <ul>
                                            <!--div-- *ngFor=" let categorias of categoriaList">
                                                {{categorias.descripcion}}
                                            </div-->
                                            <li *ngFor="let cat of filterbyCategoryinventario">
                                                <a [routerLink]="[urlshop]"
                                                   [queryParams]="{ category: cat}">
                                                    {{ cat }}
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="sub-title">
                        <div class="footer-title">
                            <h4>Ayuda</h4>
                        </div>
                        <div class="footer-contant">
                            <ul>
                                <li><a [routerLink]="['/pages/politicas-compra']">Políticas de envíos</a></li>
                                <li><a [routerLink]="['/pages/terminos-condiciones']">Términos y condiciones</a></li>
                                <li><a [routerLink]="['/pages/politicas-privacidad']">Políticas de privacidad</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="sub-title">
                        <div class="footer-title">
                            <h4>{{ 'store-information' | translate }}</h4>
                        </div>
                        <div class="footer-contant">
                            <ul class="contact-list">
                                <li><i class="fa fa-map-marker"></i>{{empresa.nombreempresa}}</li>
                                <li><i class="fa fa-phone"></i>Teléfono: {{empresa.telefono1}} - {{empresa.telefono2}}
                                </li>
                                <li style="text-transform: lowercase"><i class="fa fa-envelope-o"></i>Correo : <a>{{empresa.email}} </a>
                                </li>
                                <li><i class="fa fa-fax"></i>Dirección: {{empresa.direccion1 | lowercase }} {{empresa.direccion2 | lowercase}}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <div class="sub-footer">
        <div class="container">
            <div class="row">
                <div class="col-xl-6 col-md-6 col-sm-12">
                    <div class="footer-end">
                        <p><i class="fa fa-copyright" aria-hidden="true"></i> {{ today | date:'y'}} Ecuasolutions</p>
                    </div>
                </div>
                <div class="col-xl-6 col-md-6 col-sm-12">
                    <div class="payment-card-bottom">
                        <ul>
                            <li>
                                <a><img src="assets/images/icon/visa.png" alt=""></a>
                            </li>
                            <li>
                                <a><img src="assets/images/icon/diners.png" alt=""></a>
                            </li>
                            <li>
                                <a><img src="assets/images/icon/mastercard.png" alt=""></a>
                            </li>
                            <li>
                                <a><img src="assets/images/icon/american-express.png" alt=""></a>
                            </li>
                            <li>
                                <a><img src="assets/images/icon/discover.png" alt=""></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
<!--footer section end -->
