import {Component, OnInit, Input, ViewChild} from '@angular/core';
import {QuickViewComponent} from '../../modal/quick-view/quick-view.component';
import {CartModalComponent} from '../../modal/cart-modal/cart-modal.component';
import {Product} from '../../../classes/product';
import {ProductService} from '../../../services/product.service';
import {AppConfig} from '../../../../app.config';

@Component({
    selector: 'app-product-box-five',
    templateUrl: './product-box-five.component.html',
    styleUrls: ['./product-box-five.component.scss']
})
export class ProductBoxFiveComponent implements OnInit {

    @Input() product: Product;
    @Input() currency: any = this.productService.Currency; // Default Currency
    @Input() thumbnail: boolean = false; // Default False
    @Input() onHowerChangeImage: boolean = false; // Default False
    @Input() cartModal: boolean = false; // Default False

    @ViewChild('quickView') QuickView: QuickViewComponent;
    @ViewChild('cartModal') CartModal: CartModalComponent;

    public ImageSrc: string;
    public urlproduct = '';

    constructor(private productService: ProductService, protected appConfig: AppConfig) {
        this.urlproduct = this.appConfig.getLayoutProduct();
    }

    ngOnInit(): void {
    }

    // Get Product Color
    Color(variants) {
        const uniqColor = [];
        for (let i = 0; i < Object.keys(variants).length; i++) {
            if (uniqColor.indexOf(variants[i].color) === -1 && variants[i].color) {
                uniqColor.push(variants[i].color);
            }
        }
        return uniqColor;
    }

    // Change Variants
    ChangeVariants(color, product) {
        product.variants.map((item) => {
            if (item.color === color) {
                product.images.map((img) => {
                    if (img.image_id === item.image_id) {
                        this.ImageSrc = img.src;
                    }
                });
            }
        });
    }

    // Change Variants Image
    ChangeVariantsImage(src) {
        this.ImageSrc = src;
    }

    addToCart(product: any) {
        this.productService.addToCart(product);
    }

    addToWishlist(product: any) {
        this.productService.addToWishlist(product);
    }

    addToCompare(product: any) {
        this.productService.addToCompare(product);
    }

}
