<div class="media">
    <a [routerLink]="[urlproduct, product.id]">
        <img [defaultImage]="ImageSrc ? ImageSrc : 'assets/images/product/placeholder.jpg'"
             [lazyLoad]="ImageSrc ? ImageSrc : product.images[0].src"
             class="img-fluid lazy-loading" alt="{{ product.images[0].alt }}">
    </a>
    <div class="media-body align-self-center">
        <div class="rating">
            <bar-rating [rate]="5" [readOnly]="true"></bar-rating>
        </div>
        <a [routerLink]="[urlproduct, product.id]">
            <h6>{{ product.title | titlecase }}</h6>
        </a>
        <h4 *ngIf="!product['bandiva']">{{ product?.price * currency?.price | discount:product | currency:currency?.currency:'US$ ' }}</h4>
        <h4 *ngIf="product['bandiva']">{{ product?.price * currency?.price * 1.12 | discount:product | currency:currency?.currency:'US$ ' }}</h4>
        <ul class="color-variant">
            <li [class]="color"
                *ngFor="let color of Color(product?.variants)"
                [ngStyle]="{'background-color': color}"
                (click)="ChangeVariants(color, product)">
            </li>
        </ul>
    </div>
</div>
