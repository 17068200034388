<div class="img-wrapper">
    <div class="img-block">
        <div class="lable-wrapper">
            <span class="lable1" *ngIf="product.new">new</span>
            <span class="lable2" *ngIf="product.sale">{{ product?.discount }}% off</span>
        </div>
        <div class="front">
            <a [routerLink]="[urlproduct, product.id]">
                <img [defaultImage]="ImageSrc ? ImageSrc : 'assets/images/product/placeholder.jpg'"
                     [lazyLoad]="ImageSrc ? ImageSrc : product.images[0].src"
                     class="img-fluid lazy-loading" alt="{{ product.images[0].alt }}">
            </a>
        </div>
        <div class="back" *ngIf="onHowerChangeImage">
            <a [routerLink]="[urlproduct, product.id]">
                <img [src]="ImageSrc ? ImageSrc : product.images[1].src" class="img-fluid lazy-loading"
                     alt="{{ product.images[1].alt }}">
            </a>
        </div>
        <div class="cart-info">
            <button title="{{'add-to-cart' | translate}}" (click)="CartModal.openModal(product)"
                    *ngIf="cartModal">
                <i class="ti-shopping-cart"></i>
            </button>
            <button title="{{'add-to-cart' | translate}}" (click)="addToCart(product)"
                    *ngIf="!cartModal">
                <i class="ti-shopping-cart"></i>
            </button>
            <a href="javascript:void(0)" title="{{'add-wishlist' | translate}}" (click)="addToWishlist(product)">
                <i class="ti-heart" aria-hidden="true"></i>
            </a>
            <a href="javascript:void(0)" title="Quick View" (click)="QuickView.openModal()">
                <i class="ti-search" aria-hidden="true"></i>
            </a>
            <a href="javascript:void(0)" title="Compare" (click)="addToCompare(product)">
                <i class="ti-reload" aria-hidden="true"></i>
            </a>
        </div>
    </div>
</div>
<div class="product-detail">
    <div>
        <bar-rating [rate]="5" [readOnly]="true"></bar-rating>
        <a [routerLink]="[urlproduct, product.id]">
            <h6>{{ product?.title }}</h6>
        </a>
        <h4 *ngIf="!product['bandiva']">{{ product?.price * currency?.price | discount:product | currency:currency?.currency:'US$ ' }}<del *ngIf="product?.discount"><span class="money"> {{ product?.price * currency?.price | currency:currency?.currency:'US$ ' }}</span></del></h4>
        <h4 *ngIf="product['bandiva']">{{ product?.price * currency?.price * 1.12| discount:product | currency:currency?.currency:'US$ ' }}<del *ngIf="product?.discount"><span class="money"> {{ product?.price * currency?.price * 1.12| currency:currency?.currency:'US$ ' }}</span></del></h4>
        <ul class="color-variant" *ngIf="Color(product?.variants).length">
            <li [class]="color" *ngFor="let color of Color(product?.variants)" [ngStyle]="{'background-color': color}"
                (click)="ChangeVariants(color, product)">
            </li>
        </ul>
    </div>
</div>

<app-quick-view #quickView [product]="product" [currency]="currency"></app-quick-view>
<app-cart-modal #cartModal [product]="product" [currency]="currency" *ngIf="cartModal"></app-cart-modal>
  
