<ng-template class="theme-modal" #paymentView let-modal>
    <div class="modal-content quick-view-modal">
        <div class="modal-body">
            <button type="button" class="close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="row">
                <h3>Gracias por comprar en Sodilibro Cía. Ltda.</h3>
                <div class="col-lg-12  col-xs-12" *ngIf="cargando">
                    <div class="img-wrapper">
                        <ngx-skeleton-loader
                                [theme]="{ 'background-color': '#e8e8e8', 'border-radius': '15px', height: '20px' }"></ngx-skeleton-loader>
                    </div>
                    <p align="center">Cargando forma de pago .............</p>
                </div>
                <div class="col-lg-12  col-xs-12">
                    <div *ngIf="tipo !== 'deposito'">
                        <br>
                        <p>------------------</p>
                        <div id='app-button'></div>
                        <br>
                        <p>_--------------</p>
                    </div>
                    <div *ngIf="tipo === 'deposito'">
                        <p>Para finalizar tu pago sigue los siguientes pasos:</p>
                        <p>1. Realiza tu depósito o transferencia a cualquiera de las siguientes cuentas:</p>
                        <p>Banco del Pacífico: cuenta de ahorros 1039558649</p>
                        <p>Cooperativa JEP: cuenta de ahorros 406101753103</p>
                        <p>Nombre: Sodilibro.</p>
                        <p>Ruc: 0190363902001</p>
                        <p>    2. Envíanos el comprobante de pago al correo: <a href="ventas@sodilibro.com">ventas@sodilibro.com</a> o vía WhatsApp al
                            +593969044000.
                        </p>
                        <p>3. Recibirás una confirmación de tu compra y la factura electrónica de la misma.</p>
                        <div class="buttons">
                            <button class="view-cart btn btn-solid" (click)="grabar()">Grabar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
