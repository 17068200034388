import { Component, OnInit, HostListener } from '@angular/core';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-tap-to-top',
  templateUrl: './tap-to-top.component.html',
  styleUrls: ['./tap-to-top.component.scss']
})
export class TapToTopComponent implements OnInit {
  public show = false;

  constructor(private viewScroller: ViewportScroller) { }

  ngOnInit(): void {
  }

  // @HostListener Decorator
  @HostListener('window:scroll', [])
  onWindowScroll() {
      // tslint:disable-next-line:variable-name
      const number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
      if (number > 600) {
          // tslint:disable-next-line:indent
  	  this.show = true;
          // tslint:disable-next-line:indent
  	    } else {
          // tslint:disable-next-line:indent
  	  this.show = false;
          // tslint:disable-next-line:indent
  	}
  }

  tapToTop() {
      // tslint:disable-next-line:indent
  	this.viewScroller.scrollToPosition([0, 0]);
  }

}
