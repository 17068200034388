<header class="sticky" [ngClass]="class" [class.fixed]="stick && sticky">
    <div class="mobile-fix-option"></div>
    <div class="top-header" *ngIf="topbar">
        <div class="container">
            <div class="row">
                <div class="col-lg-7">
                    <div class="header-contact">
                        <ul>
                            <li>{{ 'topbar-title' | translate }} {{empresa.nombreempresa}}</li>
                            <li>
                                <i class="fa fa-phone" aria-hidden="true"></i>{{ 'call-us' | translate }}
                                <a>{{empresa.telefono1}} - {{empresa.telefono2}} </a>
                            </li>
                            <li>
                                <a target="_blank" href="https://www.facebook.com/sodilibro"><i
                                        class="fa fa-facebook"
                                        aria-hidden="true"></i></a>
                                <a target="_blank" href="https://www.instagram.com/sodilibro/"><i
                                        class="fa fa-instagram"
                                        aria-hidden="true"></i></a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-5 text-right">
                    <ul class="header-dropdown">
                        <li>
                            <a href="#">{{nameUser}}</a>
                        </li>
<!--                        <li class="compare">-->
<!--                            <a [routerLink]="['/shop/compare']">-->
<!--                                <i class="fa fa-random" aria-hidden="true"></i>{{ 'compare' | translate}}-->
<!--                            </a>-->
<!--                        </li>-->
                        <li class="mobile-wishlist">
                            <a [routerLink]="['/shop/wishlist']">
                                <i class="fa fa-heart" aria-hidden="true"></i> {{ 'wishlist' | translate}}
                            </a>
                        </li>
                        <li class="mobile-account">
                            <a href="javascript:void(0)" (click)="login()" *ngIf="nameUser">
                                <i class="fa fa-user" aria-hidden="true"></i> {{ 'myaccount' | translate}}
                            </a>
                            <a href="javascript:void(0)" (click)="login()" *ngIf="!nameUser">
                                <i class="fa fa-user" aria-hidden="true"></i> {{ 'login' | translate}} / {{'register' | translate}}
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <div class="main-menu">
                    <div class="menu-left">
<!--                        <app-left-menu></app-left-menu>-->
                        <div class="brand-logo">
                            <a routerLink="/home" id="headerlogo">
                                <img [src]="themeLogo" class="img-fluid" alt="logo" style="width: 200px; height: 62px">
                            </a>
                        </div>
                    </div>
                    <div class="menu-right pull-right">
                        <div>
                            <app-menu></app-menu>
                        </div>
                        <div>
                            <app-settings></app-settings>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>
