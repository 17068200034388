import {
    Component, OnInit, OnDestroy, ViewChild, TemplateRef, PLATFORM_ID, Inject
} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import {Router} from '@angular/router';
import {GrabarpedidoService} from '../../../services/grabarpedido.service';
import {ToastrService} from 'ngx-toastr';
import {AuthService} from '../../../services/auth.service';
import {Empresa} from '../../../classes/empresa';
import {LoginService} from '../../../services/login.service';

@Component({
    selector: 'app-cierresesion',
    templateUrl: './cierre-sesion.component.html',
    styleUrls: ['./cierre-sesion.component.scss']
})
export class CierreSesionComponent implements OnInit, OnDestroy {

    @ViewChild('paymentView', {static: false}) QuickView: TemplateRef<any>;
    public empresa: Empresa = new Empresa();
    public closeResult: string;
    public modalOpen = false;
    public cargando = true;

    constructor(@Inject(PLATFORM_ID) private platformId: Object, private grabarpedidoService: GrabarpedidoService,
                private router: Router, private modalService: NgbModal,
                private toastrService: ToastrService, private authService: AuthService, private loginService: LoginService) {
    }

    ngOnInit(): void {
        this.loginService.getEmpresa().subscribe(res => {
            this.empresa = res ? res : this.empresa;
        });
    }

    async openModal(datos) {
        this.modalOpen = true;
        if (isPlatformBrowser(this.platformId)) { // For SSR
            this.modalService.open(this.QuickView, {
                ariaLabelledBy: 'modal-basic-title',
                centered: true,
                windowClass: 'Quickview'
            }).result.then((result) => {
                `Result ${result}`;
            }, (reason) => {
                this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            });
        }
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }


    ngOnDestroy() {
        if (this.modalOpen) {
            this.modalService.dismissAll();
        }
    }

    grabar() {
        this.toastrService.error('Su sesión ha caducado, vuelva a iniciar sesión');
        this.modalService.dismissAll();
        this.authService.logout();

    }
}
