import {Component, OnInit, OnDestroy, Input} from '@angular/core';
import {Observable} from 'rxjs';
import {ProductService} from '../../../services/product.service';
import {Product} from '../../../classes/product';
import {AppConfig} from '../../../../app.config';

@Component({
    selector: 'app-cart-variation',
    templateUrl: './cart-variation.component.html',
    styleUrls: ['./cart-variation.component.scss']
})
export class CartVariationComponent implements OnInit, OnDestroy {

    @Input() direction: string = 'right'; // Default Direction Right

    public products: Product[] = [];
    public urlproduct = '';
    public urlshop = '';

    constructor(public productService: ProductService, protected appConfig: AppConfig) {
        this.productService.cartItems.subscribe(response => this.products = response);
        this.urlproduct = this.appConfig.getLayoutProduct();
        this.urlshop = this.appConfig.getLayoutShop();
    }

    ngOnInit(): void {
        this.productService.OpenCart = false;
    }

    closeCart() {
        this.productService.OpenCart = false;
    }

    get getTotal(): Observable<number> {
        return this.productService.cartTotalAmount();
    }

    removeItem(product: any) {
        this.productService.removeCartItem(product);
    }

    ngOnDestroy(): void {
        this.closeCart();
    }

}
