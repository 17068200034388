<!--footer-->
<footer [ngClass]="class">
    <div class="white-layout">
        <div class="container">
            <section class="small-section">
                <div class="row footer-theme2">
                    <div class="col">
                        <div class="footer-link link-white">
                            <div class="footer-brand-logo">
                                <a href="javascript:void(0)">
                                    <img [src]="themeLogo" class="img-fluid" alt="" style="width: 100px">
                                </a>
                            </div>
                            <div class="social-white">
                                <ul>
                                    <li>
                                        <a target="_blank" href="https://www.facebook.com/sodilibro"><i
                                                class="fa fa-facebook"
                                                aria-hidden="true"></i></a>
                                    </li>
                                    <li>
                                        <a target="_blank" href="https://www.instagram.com/sodilibro/"><i
                                                class="fa fa-instagram"
                                                aria-hidden="true"></i></a>
                                    </li>
                                    <li>
                                        <a target="_blank" href="https://twitter.com/Sodilibro"><i
                                                class="fa fa-twitter"
                                                aria-hidden="true"></i></a>
                                    </li>
                                </ul>
                            </div>
                            <div class="footer-title footer-mobile-title">
                                <h4>my account</h4>
                            </div>
                            <div class="footer-contant">
                                <ul>
                                    <li><a href="javascript:void(0)">mens</a></li>
                                    <li><a href="javascript:void(0)">womens</a></li>
                                    <li><a href="javascript:void(0)">clothing</a></li>
                                    <li><a href="javascript:void(0)">accessories</a></li>
                                    <li><a href="javascript:void(0)">featured</a></li>
                                    <li><a href="javascript:void(0)">service</a></li>
                                    <li><a href="javascript:void(0)">cart</a></li>
                                    <li><a href="javascript:void(0)">my order</a></li>
                                    <li><a href="javascript:void(0)">FAQ</a></li>
                                    <li><a href="javascript:void(0)">{{'new-product' | translate}}</a></li>
                                    <li><a href="javascript:void(0)">featured product</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
    <div class="sub-footer black-subfooter ">
        <div class="container">
            <div class="row ">
                <div class="col-xl-6 col-md-6 col-sm-12">
                    <div class="footer-end">
                        <p><i class="fa fa-copyright" aria-hidden="true"></i> {{ today | date:'y'}} themeforest powered
                            by
                            pixelstrap</p>
                    </div>
                </div>
                <div class="col-xl-6 col-md-6 col-sm-12">
                    <div class="payment-card-bottom">
                        <ul>
                            <li>
                                <a href="javascript:void(0)">
                                    <img src="assets/images/icon/visa.png" alt="">
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0)">
                                    <img src="assets/images/icon/mastercard.png" alt="">
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0)">
                                    <img src="assets/images/icon/american-express.png" alt="">
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0)">
                                    <img src="assets/images/icon/discover.png" alt="">
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
<!-- footer end -->
