import {Component, OnInit, Injectable, PLATFORM_ID, Inject} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';
import {Observable} from 'rxjs';
// import {TranslateService} from '@ngx-translate/core';
import {ProductService} from '../../services/product.service';
import {Product} from '../../classes/product';
import {AppConfig} from '../../../app.config';
import {redondear} from '../../utils/metodos-generales';
import {FormControl} from '@angular/forms';
import {Router} from '@angular/router';

@Component({
    selector: 'app-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

    public search: boolean = false;
    public products: Product[] = [];
    public urlproduct = '';
    public amount: any;
    public total = 0;
    public buscarCrl: FormControl = new FormControl('');
    public urlshop = '';

    public languages = [{
        name: 'English',
        code: 'en'
    }, {
        name: 'Español',
        code: 'es'
    }];

    public currencies = [{
        name: 'Euro',
        currency: 'EUR',
        price: 0.90 // price of euro
    }, {
        name: 'Rupees',
        currency: 'INR',
        price: 70.93 // price of inr
    }, {
        name: 'Pound',
        currency: 'GBP',
        price: 0.78 // price of euro
    }, {
        name: 'Dollar',
        currency: 'USD',
        price: 1 // price of usd
    }];

    constructor(@Inject(PLATFORM_ID) private platformId: Object,
                // private translate: TranslateService,
                public productService: ProductService,
                protected router: Router,
                protected appConfig: AppConfig
    ) {
        this.productService.cartItems.subscribe(response => this.products = response);
        this.urlproduct = this.appConfig.getLayoutProduct();
        this.urlshop = this.appConfig.getLayoutShop();
    }

    searchToggle(){
        this.search = !this.search;
    }
    async ngOnInit() {
        this.amount = await this.getTotal.toPromise();
        let valbaseimp = 0;
        for (let i = 0; i < this.products.length; i++) {
            const valor = Number(redondear(this.products[i].price * this.products[i].quantity, 2));
            if (this.products[i]['bandiva']) {
                valbaseimp += (valor * 0.12);
            }
        }
        this.total = valbaseimp + this.amount;
        // this.translate.use('es');
    }

    changeLanguage(code) {
        // if (isPlatformBrowser(this.platformId)) {
        //     this.translate.use(code);
        // }
    }

    get getTotal(): Observable<number> {
        return this.productService.cartTotalAmount();
    }

    removeItem(product: any) {
        this.productService.removeCartItem(product);
    }

    changeCurrency(currency: any) {
        this.productService.Currency = currency;
    }
    buscar() {
        const search = this.buscarCrl.value;
        this.router.navigate([this.urlshop], {queryParams: {buscar: search}});
        this.search = !this.search;
    }

}
