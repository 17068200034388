import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {ToastrService} from 'ngx-toastr';
import {Observable} from 'rxjs/observable';
import {VwitemsTienda} from '../classes/itemTienda';
import {EventEmitter} from '@angular/core';
import {Pais} from '../classes/pais';
import {Provincia} from '../classes/provincia';
import {Canton} from '../classes/canton';
import {Parroquia} from '../classes/parroquia';
import {AppConfig} from '../../app.config';
import {map} from 'rxjs/operators';

// ---------------------------------------------------------------------

@Injectable({
    providedIn: 'root'
})
export class ItemtiendaService {

    apiVwItemsTienda = '';
    apiUnidadTienda = '';
// ---------------------------------------------------------------------
    apiPais = '';
    apiProvincia = '';
    apiCanton = '';
    apiCantonEntrega = '';
    apiCliente = '';
    apiExist = '';
    apiParroquia = '';
    apiPreguntasFrecuentes = '';
    apiBlog = '';
    apiDashboard = '';
    apiEmpresa = '';
    apiConfiguraciones = '';
    apiConfiguracionEmpresa = '';
    findCantones$ = new EventEmitter();
    findParroquias$ = new EventEmitter();
    public VwitemsTienda;
    public BASE_URL = '';
    public Currency = {name: 'Dollar', currency: 'USD', price: 1}; // Default Currency
    public OpenCart = false;

    constructor(private http: HttpClient, protected config: AppConfig,
                private toastrService: ToastrService) {
        this.BASE_URL = this.config.getEnv('API_URL');
        this.apiVwItemsTienda = this.BASE_URL + '/api/shopcart/vwitemTienda/';
        this.apiUnidadTienda = this.BASE_URL + '/api/shopcart/unidad/';
// ---------------------------------------------------------------------
        this.apiPais = this.BASE_URL + '/api/shopcart/pais/';
        this.apiExist = this.BASE_URL + '/api/shopcart/exist/';
        this.apiProvincia = this.BASE_URL + '/api/shopcart/provincia/';
        this.apiCanton = this.BASE_URL + '/api/shopcart/canton/';
        this.apiCantonEntrega = this.BASE_URL + '/api/cantones/';
        this.apiCliente = this.BASE_URL + '/api/shopcart/cliente/';
        this.apiParroquia = this.BASE_URL + '/api/shopcart/parroquia/';
        this.apiBlog = this.BASE_URL + '/api/blog/';
        this.apiDashboard = this.BASE_URL + '/api/cardashboard/';
        this.apiEmpresa = this.BASE_URL + '/api/carempresa/';
        this.apiPreguntasFrecuentes = this.BASE_URL + '/api/preguntasfrecuentes/';
        this.apiConfiguracionEmpresa = this.BASE_URL + '/api/carconfiguraciones/';
    }

    getItemsApi(): Observable<VwitemsTienda[]> {
        return this.http.get<VwitemsTienda[]>(this.apiVwItemsTienda);
    }

    getPreguntasFrecuentes(): Observable<any[]> {
        return this.http.get<any[]>(this.apiPreguntasFrecuentes);
    }

    getBlog(): Observable<any[]> {
        return this.http.get<any[]>(this.apiBlog);
    }

    getDashboard(params?): Observable<any[]> {
        let httpParams = new HttpParams();
        if (params && params.length > 0) {
            for (let i = 0; i < params.length; i++) {
                httpParams = httpParams.append(params[i].param, params[i].value);
            }
        }
        return this.http.get<any[]>(this.apiDashboard, {params: httpParams}).pipe();
    }

    getEmpresa(params?): Observable<any[]> {
        let httpParams = new HttpParams();
        if (params && params.length > 0) {
            for (let i = 0; i < params.length; i++) {
                httpParams = httpParams.append(params[i].param, params[i].value);
            }
        }
        return this.http.get<any[]>(this.apiEmpresa + '1/', {params: httpParams}).pipe();
    }

    getExistencia(params?): Observable<any[]> {
        let httpParams = new HttpParams();
        if (params && params.length > 0) {
            for (let i = 0; i < params.length; i++) {
                httpParams = httpParams.append(params[i].param, params[i].value);
            }
        }
        return this.http.get<any[]>(this.apiExist, {params: httpParams}).pipe();
    }

    getConfiguracionEmpresa(params?): Observable<any[]> {
        let httpParams = new HttpParams();
        if (params && params.length > 0) {
            for (let i = 0; i < params.length; i++) {
                httpParams = httpParams.append(params[i].param, params[i].value);
            }
        }
        return this.http.get<any[]>(this.apiConfiguracionEmpresa, {params: httpParams}).pipe();
    }


    /*
    ---------------------------------------------
    ---- Pais Provincia canton parroquia --------
    ---------------------------------------------
  */
    /**
     * este metodo recuepra el pais
     * del item
     */
    getPais(): Observable<Pais[]> {
        // @ts-ignore
        return this.http.get<Pais[]>(this.apiPais);
    }

    /**
     * este metodo recuepra la provincia
     * del item
     */
    getProvincia(): Observable<Provincia[]> {
        // @ts-ignore
        return this.http.get<Provincia[]>(this.apiProvincia);
    }

    /**
     * este metodo recuepra el canton
     * del item
     */
    getCanton(idProvincia: string): Observable<Canton[]> {
        // @ts-ignore
        return this.http.get<Canton[]>(this.apiCanton.concat('?idprovincia=', idProvincia));
    }

    getCantonEntrega(idProvincia: string): Observable<Canton[]> {
        // @ts-ignore
        return this.http.get<Canton[]>(this.apiCantonEntrega.concat('?provincia__idprovincia=', idProvincia, ));
    }

    /**
     * este metodo recuepra el cliente
     * del item
     */
    getCliente(ruc: string): Observable<any[]> {
        // @ts-ignore
        return this.http.get<any[]>(this.apiCliente.concat('?codprovcli=', ruc));
    }

    postCliente(data): Observable<any[]> {
        // @ts-ignore
        return this.http.post<any[]>(this.apiCliente, data, {observe: 'response'});
    }
    postPayphone(url,  data){
        // @ts-ignore
        const headers = new HttpHeaders({
            Authorization: 'Bearer 1mDRsG0EEYhZdVuKM4xXVsOYFJSHOfUkNc4_S5uUW5_wVKm0QyQ8g2rVyFTUmS28YAcNKEXkOZ0e_txq61ouOC2pf4HxIovQQSPWM3dyv_y-VQ-_r1L1Vz85sYDbUNrKqtNrbcgOYUf_FDlwLU6RpGD-IbL9my6l9Mgqg_0yRcrObkHvV-hLwxI0o3vtfu8jLNGK5GBLJuUW1q0LLgjiq8PMWFDUAZjOxYGpkcgZ2zXUCV2v5Zq_yhHIM7jxKGzw41HomdL0AWhh2yvRg8I9HD80fBSSbx_wLOBwapcXvkvfS30L5LlpkEZ3KgTm2IGz2v5ZUw'
        });
        return this.http.post(url, data, {headers}).pipe(map(res => res));
    }

    /**
     * este metodo recuepra el canton
     * del item
     */
    getParroquia(idCanton: string): Observable<Parroquia[]> {
        // @ts-ignore
        return this.http.get<Parroquia[]>(this.apiParroquia.concat('?search=', idCanton));
    }
}
