import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {Router} from '@angular/router';
import {JwtHelperService} from '@auth0/angular-jwt';
import {AppConfig} from '../../app.config';
import {BehaviorSubject, Observable} from 'rxjs';
import {User} from '../classes/user';
export const TOKEN_NAME = 'jwt_token';

@Injectable({providedIn: 'root'})
export class AuthService {
    public url: string;
    private user$ = new BehaviorSubject<User>(null);
    user = this.user$.asObservable();

    constructor(private http: HttpClient, private router: Router, private config: AppConfig) {
        this.url = config.getEnv('API_URL');
    }

    changeUser(usuario: User) {
        this.user$.next(usuario);
    }

    clearUser() {
        this.user$.next(null);
    }

    login(data: string): Observable<any> {
        const url = `${this.url}/login/`;
        const headers = new HttpHeaders({'Content-Type': 'application/json', 'No-Auth': 'True'});
        return this.http.post(url, data, {headers: headers}).pipe(map(res => res));
    }

    getToken(): string {
        return localStorage.getItem(TOKEN_NAME);
    }

    getUsername(): string {
        return localStorage.getItem('username');
    }

    getCodigoGrupo(): string {
        return localStorage.getItem('codgrupo');
    }

    getUserId() {
        return localStorage.getItem('user_id');
    }
    getClienteId() {
        return localStorage.getItem('user_id');
    }

    setToken(token: string): void {
        localStorage.setItem(TOKEN_NAME, token);
    }

    isTokenExpired(token?: string): boolean {
        const helper = new JwtHelperService();
        if (!token) {
            token = this.getToken();
        }
        if (!token) {
            return true;
        }
        return helper.isTokenExpired(token);
    }

    logout() {
        console.log('cerrando sesion');
        localStorage.removeItem(TOKEN_NAME);
        localStorage.removeItem('user_id');
        localStorage.removeItem('menu');
        localStorage.removeItem('transacciones');
        localStorage.removeItem('permisos');
        localStorage.removeItem('gnopcion');
        this.router.navigate(['/pages/login']);
    }

    getCurrentUser(): Observable<User> {
        const userid = this.getUserId();
        const url = `${this.url}/api/usuarios/${userid}/`;
        return this.http.get(url).pipe(map((result: User) => result));
    }

    updateCurrentUser(data: any) {
        const user = this.getUserId();
        return this.http.put(`${this.url}/api/usuarios/${user}/`, data).pipe(map(res => res));
    }

}

export interface PermisoTransaccion {
    crear: boolean;
    ver: boolean;
    modificar: boolean;
    eliminar: boolean;
    aprobar: boolean;
    desaprobar: boolean;
    despachar: boolean;
    anular: boolean;
    codtrans: string;
}
