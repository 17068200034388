import {Injectable, HostListener} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {LoginService} from './login.service';
import {Categorias} from '../classes/categorias';
import {CategoriasService} from './categorias.service';
import {AppConfig} from '../../app.config';

// Menu
export interface Menu {
    path?: string;
    title?: string;
    type?: string;
    megaMenu?: boolean;
    image?: string;
    active?: boolean;
    badge?: boolean;
    param?: any;
    badgeText?: string;
    children?: Menu[];
}

@Injectable({
    providedIn: 'root'
})

export class NavService {
    public categoriaList: Categorias[] = [];
    public nameUser = 'username';
    public nameuser = 'Bienvenido ';
    public screenWidth: any;
    public leftMenuToggle = false;
    public mainMenuToggle = false;
    public urlshop = '';

    MENUITEMS: Menu[] = [
        {
            title: 'Inicio', type: 'link', active: true, badge: false, badgeText: 'new', path: '/home'
        },
        {
            title: 'Categorias', type: 'sub', badge: false, badgeText: 'new', active: false, children: []
        },
        {
            title: 'Tienda', type: 'link', active: true, badge: false, badgeText: 'new', path: '/shop/collection/left/sidebar'
        },
        {
            title: 'Contacto', type: 'link', active: true, badge: false, badgeText: 'new', path: '/pages/contact'
        }
    ];

    constructor(protected authService: LoginService, public categoriaService: CategoriasService, protected appConfig: AppConfig) {
        this.nameUser = this.authService.getNombre();
        this.urlshop = this.appConfig.getLayoutShop();
        if (this.authService.getNombre() != null) {
            const nom = this.nameUser.split(' ');
            this.nameUser = this.nameuser.concat(nom[2], ' ', nom[0]);
        } else {
            this.nameUser = '';
        }
        this.categoriaService.getCategoriasApi().subscribe((categorias) => {
            this.categoriaList = categorias['results'];
            for (let i = 0; i < this.categoriaList.length; i++) {
                this.MENUITEMS[1].children.push({
                    path: this.urlshop,
                    param: {category : this.categoriaList[i].descripcion},
                    title: this.categoriaList[i].descripcion,
                    type: 'link'
                });
            }
        });
    }

    LEFTMENUITEMS: Menu[] = [
        {
            title: 'clothing', type: 'sub', megaMenu: true, active: false, children: [
                {
                    title: 'mens fashion', type: 'link', active: false, children: [
                        {path: '/home', title: 'sports wear', type: 'link'},
                        {path: '/home', title: 'top', type: 'link'},
                        {path: '/home', title: 'bottom', type: 'link'},
                        {path: '/home', title: 'ethic wear', type: 'link'},
                        {path: '/home', title: 'sports wear', type: 'link'},
                        {path: '/home', title: 'shirts', type: 'link'},
                        {path: '/home', title: 'bottom', type: 'link'},
                        {path: '/home', title: 'ethic wear', type: 'link'},
                        {path: '/home', title: 'sports wear', type: 'link'}
                    ]
                }
            ]
        }
    ];

// Array
    items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
    leftMenuItems = new BehaviorSubject<Menu[]>(this.LEFTMENUITEMS);

// Windows width
    @HostListener('window:resize', ['$event'])
    onResize(event?) {
        this.screenWidth = window.innerWidth;
    }

}
