<div *ngIf="!loader">
  <div class="img-wrapper">
    <div class="lable-block">
      <span class="lable3" *ngIf="product.new">{{'new' | translate}}</span>
      <span class="lable4" *ngIf="product.sale">{{'on-sale' | translate}}</span>
    </div>
    <div class="front">
      <a [routerLink]="[urlproduct, product.id]" [queryParams]="parametros">
        <img
                [defaultImage]="ImageSrc ? ImageSrc : 'assets/images/product/placeholder.jpg'"
                [lazyLoad]="ImageSrc ? ImageSrc : product.images[0].src"
                class="img-fluid lazy-loading"
                alt="{{ product.images[0].alt }}" />
      </a>
    </div>
    <div class="back" *ngIf="onHowerChangeImage">
      <a [routerLink]="[urlproduct, product.id]">
        <img [src]="ImageSrc ? ImageSrc : product.images[1].src" class="img-fluid lazy-loading" alt="{{ product.images[1].alt }}">
      </a>
    </div>
    <ul class="product-thumb-list" *ngIf="thumbnail">
      <li class="grid_thumb_img" [class.active]="ImageSrc == image.src" *ngFor="let image of product.images">
        <a href="javascript:void(0)" (mouseover)="ChangeVariantsImage(image.src)">
          <img [lazyLoad]="image.src">
        </a>
      </li>
    </ul>
    <div class="cart-info cart-wrap" *ngIf="!detalle">
      <a href="javascript:void(0)" title="{{'add-to-cart' | translate}}" (click)="CartModal.openModal(product)"
         *ngIf="cartModal">
        <i class="ti-shopping-cart"></i>
      </a>
      <a href="javascript:void(0)" title="{{'add-to-cart' | translate}}" (click)="addToCart(product)"
         *ngIf="!cartModal">
        <i class="ti-shopping-cart"></i>
      </a>
      <a href="javascript:void(0)" title="{{'add-wishlist' | translate}}" (click)="addToWishlist(product)">
        <i class="ti-heart" aria-hidden="true"></i>
      </a>
      <a href="javascript:void(0)" title="Vista Rapida" (click)="QuickView.openModal()">
        <i class="ti-search" aria-hidden="true"></i>
      </a>
<!--      <a href="javascript:void(0)" title="{{'compare' | translate}}" (click)="addToCompare(product)">-->
<!--        <i class="ti-reload" aria-hidden="true"></i>-->
<!--      </a>-->
    </div>
  </div>
  <div class="product-detail">
    <div>
      <bar-rating [rate]="5" [readOnly]="true"></bar-rating>
      <a [routerLink]="[urlproduct, product.id]" [queryParams]="parametros">
        <h6>{{ product?.title.substr(0, 50) + ' ...' }}</h6>
      </a>
<!--      <p>{{ product?.description }}</p>-->
      <h4 *ngIf="!product['bandiva']"> {{ product?.price * currency?.price | discount:product | currency:currency?.currency:'US$ ' }}<del *ngIf="product?.discount"><span class="money"> {{ product?.price * currency?.price | currency:currency?.currency:'US$ ' }}</span></del></h4>
      <h4 *ngIf="product['bandiva']"> {{ product?.price * currency?.price * 1.12 | currency:currency?.currency:'US$ ' }}<del *ngIf="product?.discount"><span class="money"> {{ product?.price * currency?.price * 1.12| currency:currency?.currency:'US$ ' }}</span></del></h4>
      <ul class="color-variant" *ngIf="Color(product?.variants).length">
        <li [class]="color" *ngFor="let color of Color(product?.variants)" [ngStyle]="{'background-color': color}"
            (click)="ChangeVariants(color, product)">
        </li>
      </ul>
    </div>
  </div>
</div>
<app-skeleton-product-box *ngIf="loader"></app-skeleton-product-box>
<app-quick-view #quickView [product]="product" [currency]="currency"></app-quick-view>
<app-cart-modal #cartModal [product]="product" [currency]="currency" *ngIf="cartModal"></app-cart-modal>

