<!-- Start Categories List -->
<!--<div class="collection-collapse-block" [class.open]="collapse">-->
<!--    <h3 class="collapse-block-title" (click)="collapse = !collapse">{{ 'category' | translate}}</h3>-->
<!--    <div class="collection-collapse-block-content">-->
<!--        <div class="collection-brand-filter">-->
<!--            <ul class="pruebita category-list">-->
<!--                &lt;!&ndash;div&#45;&#45; *ngFor=" let categorias of categoriaList">-->
<!--                    {{categorias.descripcion}}-->
<!--                </div&ndash;&gt;-->
<!--                <li *ngFor="let cat of filterbyCategoryinventario" class="pruebita">-->
<!--                    <a [routerLink]="[urlshop]" class="pruebita"-->
<!--                       [queryParams]="{ category: cat}" [ngStyle]="{'color': cat == category ? '#E86B00' : ''}">-->
<!--                        <span style="color: #E86B00;"> > </span>{{ cat }}-->
<!--                    </a>-->
<!--                </li>-->
<!--            </ul>-->
<!--        </div>-->
<!--    </div>-->
<!--</div>-->
<div class="collection-collapse-block" [class.open]="collapse">
<!--    <h3 class="collapse-block-title" (click)="collapse = !collapse">Category</h3>-->
    <div class="collection-collapse-block-content">
        <div class="collection-brand-filter">
            <ul class="pruebita category-list">
                <li *ngFor="let cat of filterbyCategoryinventario" class="pruebita">
                    <a [routerLink]="[urlshop]" class="pruebita" style="text-transform: initial"
                       [queryParams]="{ category: cat}" [ngStyle]="{'color': cat == category ? '#E86B00' : ''}">
                        <span style="color: #E86B00;"> > </span> {{ cat }}
                    </a>
                </li>
            </ul>
        </div>
    </div>
</div>
<!-- End Categories List -->
