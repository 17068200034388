import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/observable';
import {Empresa} from '../classes/empresa';
import {AppConfig} from '../../app.config';

@Injectable({
    providedIn: 'root'
})
export class EmpresaService {
    public BASE_URL: string;

    constructor(private http: HttpClient, protected config: AppConfig) {
        this.BASE_URL = this.config.getEnv('API_URL');
    }

    // esta api recupera los datos de la empresa para ser cargados en el pagina
    getEmpresaApi(): Observable<any> {
        return this.http.get(this.BASE_URL + '/api/shopcart/empresa/').pipe();
    }
}
