import {throwError as observableThrowError, Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map, catchError} from 'rxjs/operators';

// import {GNOpcion} from './models/gnopcion.model';

@Injectable(
    {providedIn: 'root'}
)
export class AppConfig {
    private env: Object = null;

    constructor(private http: HttpClient) {
    }

    // public getConfiguracion(clave: string) {
    // const gnopcion_json = localStorage.getItem('gnopcion');
    // const configuraciones: Array<GNOpcion> = JSON.parse(gnopcion_json);
    // const config = configuraciones.find(res => res.codigo === clave);
    // return config ? config.valor : '';
    // return '';
    // }

    public getLayoutMain() {
        return localStorage.getItem('layout') || '';
    }

    public setLayoutMain(valor: string) {
        localStorage.setItem('layout', valor);
    }

    public getLayoutShop() {
        const shop = localStorage.getItem('shop') || 'left';
        return `/shop/collection/${shop}/sidebar`;
    }

    public setLayoutShop(valor: string) {
        localStorage.setItem('shop', valor);
    }

    public getLayoutProduct() {
        const product = localStorage.getItem('layoutproduct') || 'left/sidebar/';
        return `/shop/product/${product}`;
    }

    public setProduct(valor: string) {
        localStorage.setItem('layoutproduct', valor);
    }

    public getEnv(key: any) {
        return this.env[key];
    }

    public load() {
        return new Promise((resolve, reject) => {
            this.http.get('./assets/config/env.json').pipe(map(res => res), catchError((error: any): any => {
                resolve(true);
                return observableThrowError(error.json().error || 'Server error');
            })).subscribe((envResponse) => {
                this.env = envResponse;
                let request: any = null;
                const host: string = envResponse['API_URL'];
                switch (envResponse['ENV']) {
                    case 'development': {
                        request = this.http.get(`${host}/api/config/`);
                    }
                        break;
                }
                resolve(true);
            });
        });
    }
}
