<ng-template class="theme-modal" #paymentView let-modal>
    <div class="modal-content quick-view-modal">
        <div class="modal-body">
            <button type="button" class="close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="row">
                <h3>Tiempo Inactiva.</h3>
                <div class="col-lg-12  col-xs-12" *ngIf="cargando">
                    <div class="img-wrapper">
                        <ngx-skeleton-loader
                                [theme]="{ 'background-color': '#e8e8e8', 'border-radius': '15px', height: '20px' }"></ngx-skeleton-loader>
                    </div>
                    <p align="center">Cargando forma de pago .............</p>
                </div>
                <div class="col-lg-12  col-xs-12">

                </div>
            </div>
        </div>
    </div>
</ng-template>
