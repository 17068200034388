import {Component, OnInit, Input} from '@angular/core';
import {Product} from '../../../classes/product';
import {AppConfig} from '../../../../app.config';

@Component({
    selector: 'app-product-box-vertical',
    templateUrl: './product-box-vertical.component.html',
    styleUrls: ['./product-box-vertical.component.scss']
})
export class ProductBoxVerticalComponent implements OnInit {

    @Input() product: Product;
    @Input() currency: any;

    public ImageSrc: string;
    public urlproduct = '';

    constructor(protected appConfig: AppConfig) {
        this.urlproduct = this.appConfig.getLayoutProduct();
    }

    ngOnInit(): void {
    }

    // Get Product Color
    Color(variants) {
        const uniqColor = [];
        for (let i = 0; i < Object.keys(variants).length; i++) {
            if (uniqColor.indexOf(variants[i].color) === -1 && variants[i].color) {
                uniqColor.push(variants[i].color);
            }
        }
        return uniqColor;
    }

    // Change Variants
    ChangeVariants(color, product) {
        product.variants.map((item) => {
            if (item.color === color) {
                product.images.map((img) => {
                    if (img.image_id === item.image_id) {
                        this.ImageSrc = img.src;
                    }
                });
            }
        });
    }

}
