import {Component, OnInit} from '@angular/core';
import {Categorias} from '../../classes/categorias';
import {CategoriasService} from '../../services/categorias.service';
import {AppConfig} from '../../../app.config';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
    selector: 'app-categories',
    templateUrl: './categories.component.html',
    styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit {
    public urlshop = '';
    public collapse = true;
    public categoriaList: Categorias[] = [];
    public category: string;
    constructor(public categoriaService: CategoriasService, protected appConfig: AppConfig, protected route: ActivatedRoute) {
        this.urlshop = this.appConfig.getLayoutShop();
        this.route.queryParams.subscribe(params => {
            this.category = params.category ? params.category : '';
        });
    }

    ngOnInit(): void {
        this.categoriaService.getCategoriasApi().subscribe((categorias) => {
            this.categoriaList = categorias['results'];
        });
    }

    get filterbyCategoryinventario() {
        const category = [...new Set(this.categoriaList.map(categoria => categoria.descripcion))];
        return category;
    }
}
