import {Component, OnInit, Input} from '@angular/core';
import {EmpresaService} from '../../services/empresa.service';
import {LoginService} from '../../services/login.service';
import {FooterBaseComponent} from '../footer-base/footer-base.component';
import {Categorias} from '../../classes/categorias';
import {CategoriasService} from '../../services/categorias.service';
import {AppConfig} from '../../../app.config';
import {ToastrService} from 'ngx-toastr';

@Component({
    selector: 'app-footer-one',
    templateUrl: './footer-one.component.html',
    styleUrls: ['./footer-one.component.scss']
})
export class FooterOneComponent extends FooterBaseComponent implements OnInit {
    @Input() class = 'footer-light'; // Default class
    public urlshop = '';
    public categoriaList: Categorias[] = [];

    constructor(protected authService: LoginService, public categoriaService: CategoriasService, protected appConfig: AppConfig, protected toastrService: ToastrService) {
        super(authService, toastrService);
        this.urlshop = this.appConfig.getLayoutShop();
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.categoriaService.getCategoriasApi().subscribe((categorias) => {
            this.categoriaList = categorias['results'];
        });
    }

    get filterbyCategoryinventario() {
        const category = [...new Set(this.categoriaList.map(categoria => categoria.descripcion))];
        return category;
    }

}
