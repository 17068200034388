import {
    Component, OnInit, OnDestroy, ViewChild, TemplateRef, Input,
    Injectable, PLATFORM_ID, Inject
} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import {ActivatedRoute, Router} from '@angular/router';
import {Product} from '../../../classes/product';
import {ProductService} from '../../../services/product.service';
import {AppConfig} from '../../../../app.config';

@Component({
    selector: 'app-quick-view',
    templateUrl: './quick-view.component.html',
    styleUrls: ['./quick-view.component.scss']
})
export class QuickViewComponent implements OnInit, OnDestroy {

    @Input() product: Product;
    @Input() currency: any;
    @ViewChild('quickView', {static: false}) QuickView: TemplateRef<any>;

    public closeResult: string;
    public ImageSrc: string;
    public counter: number = 1;
    public modalOpen: boolean = false;
    public urlproduct = '';
    public preciodescuento = [];
    public parametros = null;

    constructor(@Inject(PLATFORM_ID) private platformId: Object,
                protected appConfig: AppConfig,
                protected route: ActivatedRoute,
                private router: Router, public modalService: NgbModal,
                public productService: ProductService) {
        this.urlproduct = this.appConfig.getLayoutProduct();
        this.route.queryParams.subscribe(params => {
            this.parametros = params;
        });
        // this.productService.listpreciodescuento().subscribe(res => {
        //     this.preciodescuento = res['results'];
        // });
    }

    ngOnInit(): void {
    }

    openModal() {
        this.modalOpen = true;
        if (isPlatformBrowser(this.platformId)) { // For SSR
            this.modalService.open(this.QuickView, {
                size: 'lg',
                ariaLabelledBy: 'modal-basic-title',
                centered: true,
                windowClass: 'Quickview'
            }).result.then((result) => {
                `Result ${result}`;
            }, (reason) => {
                this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            });
        }
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    // Get Product Color
    Color(variants) {
        const uniqColor = [];
        for (let i = 0; i < Object.keys(variants).length; i++) {
            if (uniqColor.indexOf(variants[i].color) === -1 && variants[i].color) {
                uniqColor.push(variants[i].color);
            }
        }
        return uniqColor;
    }

    // Get Product Size
    Size(variants) {
        const uniqSize = [];
        for (let i = 0; i < Object.keys(variants).length; i++) {
            if (uniqSize.indexOf(variants[i].size) === -1 && variants[i].size) {
                uniqSize.push(variants[i].size);
            }
        }
        return uniqSize;
    }

    // Change Variants
    ChangeVariants(color, product) {
        product.variants.map((item) => {
            if (item.color === color) {
                product.images.map((img) => {
                    if (img.image_id === item.image_id) {
                        this.ImageSrc = img.src;
                    }
                });
            }
        });
    }

    // Increament
    increment() {
        this.counter++;
        this.asignarprecio();
    }

    // Decrement
    decrement() {
        if (this.counter > 1) {
            this.counter--;
            this.asignarprecio();
        }
    }

    // Add to cart
    async addToCart(product: any) {
        product.quantity = Number(this.counter) || 1;
        const status = await this.productService.addToCart(product);
        if (status) {
            this.router.navigate(['/shop/cart']);
        }
    }

    asignarprecio() {
        let precio = Number(localStorage.getItem('numprecio') || '0');
        if (precio < 2) {
            const descuento = this.preciodescuento.filter(p => (p['rangoinicio'] <= this.counter) && (this.counter <= p['rangofin']));
            precio = descuento.length > 0 ? descuento[0]['precio'] : 1;
        }
        this.product.price = this.product['precio' + precio];
    }

    ngOnDestroy() {
        if (this.modalOpen) {
            this.modalService.dismissAll();
        }
    }

}
