export class Empresa {
    ruc?: number;
    nombreempresa?: string;
    razonsocial?: string;
    email?: string;
    telefono1?: string;
    telefono2?: string;
    direccion1?: string;
    direccion2?: string;
    logotipo?: string;

    constructor(data?) {
        data = data || {};
        this.ruc = data.ruc || '';
        this.nombreempresa = data.nombreempresa || '';
        this.razonsocial = data.razonsocial || '';
        this.email = data.email || '';
        this.telefono1 = data.telefono1 || '';
        this.telefono2 = data.telefono2 || '';
        this.direccion1 = data.direccion1 || '';
        this.direccion2 = data.direccion2 || '';
        this.logotipo = data.logotipo || '';
    }
}
