import {Component, OnInit, Input, HostListener} from '@angular/core';
import {LoginService} from '../../services/login.service';
import {Empresa} from '../../classes/empresa';
import {HeaderBaseComponent} from '../header-base/header-base.component';
import {Router} from '@angular/router';
import {AppConfig} from '../../../app.config';

@Component({
    selector: 'app-header-four',
    templateUrl: './header-four.component.html',
    styleUrls: ['./header-four.component.scss']
})
export class HeaderFourComponent extends HeaderBaseComponent implements OnInit {

    @Input() class: string = 'header-2 header-6';


    constructor(protected authService: LoginService, protected router: Router, appConfig: AppConfig) {
        super(authService, router, appConfig);
    }

    ngOnInit(): void {
        super.ngOnInit();
    }
}
