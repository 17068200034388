<div class="img-wrapper">
    <div class="ribbon" *ngIf="product.new"><span>new</span></div>
    <div class="front">
        <a [routerLink]="[urlproduct, product.id]">
            <img  [defaultImage]="'assets/images/product/placeholder.jpg'"
                  [lazyLoad]="product.images[0].src" class="img-fluid"
                  alt="{{ product.images[0].alt }}">
        </a>
    </div>
    <div class="cart-info cart-wrap">
        <a href="javascript:void(0)" (click)="addToWishlist(product)" title="{{'add-wishlist' | translate}}">
            <i class="fa fa-heart" aria-hidden="true"></i>
        </a>
        <button title="{{'add-to-cart' | translate}}" (click)="CartModal.openModal(product)" *ngIf="cartModal">
            <i class="ti-shopping-cart"></i> {{'add-to-cart' | translate}}
        </button>
        <button title="{{'add-to-cart' | translate}}" (click)="addToCart(product)" *ngIf="!cartModal">
            <i class="ti-shopping-cart"></i> {{'add-to-cart' | translate}}
        </button>
        <a href="javascript:void(0)" (click)="addToCompare(product)" title="Compare">
            <i class="fa fa-refresh" aria-hidden="true"></i>
        </a>
        <a href="javascript:void(0)" class="mobile-quick-view" (click)="QuickView.openModal()" title="Quick View">
            <i class="ti-search" aria-hidden="true"></i>
        </a>
    </div>
    <div class="quick-view-part">
        <a href="javascript:void(0)" (click)="QuickView.openModal()" title="Quick View">
            <i class="ti-search" aria-hidden="true"></i>
        </a>
    </div>
</div>
<div class="product-info">
    <bar-rating [rate]="5" [readOnly]="true"></bar-rating>
    <a [routerLink]="[urlproduct, product.id]">
        <h6>{{ product?.title }}</h6>
    </a>
    <h4 *ngIf="!product['bandiva']">{{ product?.price * currency?.price | discount:product | currency:currency?.currency:'US$ ' }}</h4>
    <h4 *ngIf="product['bandiva']">{{ product?.price * currency?.price * 1.12 | discount:product | currency:currency?.currency:'US$ ' }}</h4>
</div>

<app-quick-view #quickView [product]="product" [currency]="currency"></app-quick-view>
<app-cart-modal #cartModal [product]="product" [currency]="currency" *ngIf="cartModal"></app-cart-modal>
