import {Component, OnInit, Input, HostListener} from '@angular/core';
import {Empresa} from '../../classes/empresa';
import {LoginService} from '../../services/login.service';
import {ToastrService} from 'ngx-toastr';

@Component({
    selector: 'app-footer-base',
    templateUrl: './footer-base.component.html',
})
export class FooterBaseComponent implements OnInit {
    @Input() themeLogo = 'assets/images/ecuasolutions/ecuasolutions.png'; // Default Logo
    @Input() newsletter = true; // Default True
    public today: number = Date.now();

    public empresa: Empresa = new Empresa();

    constructor(protected authService: LoginService, protected toastrService: ToastrService) {
    }

    ngOnInit(): void {
        this.authService.getEmpresa().subscribe(res => {
            this.empresa = res ? res : this.empresa;
        });
    }

    subscribirse() {
        this.toastrService.info('Se ha subcrito al boletín de información');
    }
}

