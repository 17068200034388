import {Component, OnInit, Input} from '@angular/core';
import {NewProductSlider} from '../../../data/slider';
import {Product} from '../../../classes/product';
import {ProductService} from '../../../services/product.service';

@Component({
    selector: 'app-product-box-vertical-slider',
    templateUrl: './product-box-vertical-slider.component.html',
    styleUrls: ['./product-box-vertical-slider.component.scss']
})
export class ProductBoxVerticalSliderComponent implements OnInit {

    @Input() title: string = 'new-product'; // Default
    @Input() type: string = 'CAROLAS'; // Default Fashion

    public products: Product[] = [];

    public NewProductSliderConfig: any = NewProductSlider;

    constructor(public productService: ProductService) {
        this.productService.listproducts([{param: 'new', value: true}, {param: 'limit', value: 6}]).subscribe(res => {
            this.products = res['results'];
        });
    }

    ngOnInit(): void {
    }

}
