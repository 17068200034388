<!--footer section -->
<footer [ngClass]="class" style="background-color: #f8f8f8 ">
    <div class="" *ngIf="mainFooter">
        <div class="container">
            <section class="small-section">
                <div class="row">
                    <div class="col-md-4 service-block">
                        <div class="media">
                            <div class="media-body">
                                <div style="color:#18a0cf;"><i style="color: #E86B00" class="fa fa-map-marker"></i>&nbsp;<b>SODILIBRO
                                    CENTRO</b></div>
                                <div>Benigno Malo 5-80 y Juan Jaramillo</div>
                                <div>Telefax: +593 07 284 3137</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 service-block">
                        <div class="media">
                            <div class="media-body">
                                <div style="color: #18a0cf"><i style="color: #E86B00" class="fa fa-map-marker"></i>&nbsp;<b>SODILIBRO
                                    UDA</b></div>
                                <div>Hernán Malo y Av. 24 de Mayo</div>
                                <div>Telefax: +593 07 409 1000 ext: 800</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 service-block">
                        <div class="media">
                            <div class="media-body">
                                <form class="form-inline subscribe-form" target="_blank" method="post">
                                    <input type="email" #name name="EMAIL" id="mce-EMAIL" class="form-control"
                                           placeholder="{{ 'enter-your-mail' | translate}}">
                                    <button type="button" (click)="subscribirse()" class="btn btn-primary"
                                            style="background-color: #18a0cf">SUSCRIBIRSE
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
    <div *ngIf="subFooter">

        <section class="service border-section small-section" style="background-color: #f8f8f8 ">
            <div class="sub-footer" style="background-color: #f8f8f8 ">
                <div class="container" style="background-color: #f8f8f8 ">
                    <div class="row" style="background-color: #f8f8f8 ">
                        <div class="col-xl-7 col-md-7 col-sm-12">
                            <div class="payment-card-bottom">
                                <ul>
                                    <li>
                                        <a target="_blank" href="https://www.facebook.com/sodilibro">
                                            <i style="color: #18a0cf; font-size: 25px" class="fa fa-facebook"
                                               aria-hidden="true"></i></a>
                                    </li>
                                    <li>
                                        <a target="_blank" href="https://www.instagram.com/sodilibro/">
                                            <i style="color: #18a0cf; font-size: 25px" class="fa fa-instagram"
                                               aria-hidden="true"></i></a>
                                    </li>
                                    <li>
                                        <a target="_blank" href="https://twitter.com/Sodilibro">
                                            <i style="color: #18a0cf; font-size: 25px" class="fa fa-twitter"
                                               aria-hidden="true"></i></a>
                                    </li>
                                    <li>
                                        <a target="_blank"
                                           href="https://api.whatsapp.com/send?phone=+593969044000&text=Necesito información acerca de un producto ofertado en la página web.">
                                            <i style="color: #18a0cf; font-size: 25px" class="fa fa-whatsapp"
                                               aria-hidden="true"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a target="_blank" href="mailto:{{empresa.email}}">
                                            <i style="color: #18a0cf; font-size: 25px" class="fa fa-envelope"
                                               aria-hidden="true"></i></a>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="offset-1"></div>
                <div class="col-md-2 service-block">
                    <div class="media">
                        <div class="media-body">
                            <a [routerLink]="['/pages/conocenos']" style="color: #212529">Conócenos</a>
                        </div>
                    </div>
                </div>
                <div class="col-md-2 service-block">
                    <div class="media">
                        <div class="media-body">
                            <a [routerLink]="['/pages/ayuda']" style="color: #212529">Ayuda</a>
                        </div>
                    </div>
                </div>
                <div class="col-md-2 service-block">
                    <div class="media">
                        <div class="media-body">
                            <a [routerLink]="['/pages/politicas-compra']" style="color: #212529">Políticas de compra</a>
                        </div>
                    </div>
                </div>
                <div class="col-md-2 service-block">
                    <div class="media">
                        <div class="media-body">
                            <a [routerLink]="['/pages/terminos-condiciones']" style="color: #212529">Términos y
                                condiciones</a>
                        </div>
                    </div>
                </div>
                <div class="col-md-2 service-block">
                    <div class="media">
                        <div class="media-body">
                            <a [routerLink]="['/pages/politicas-privacidad']" style="color: #212529">Políticas de
                                privacidad</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
    <div class="sub-footer" style="background-color: #f8f8f8 ">
        <div class="container">
            <div class="row">
                <div class="col-xl-12 col-md-12 col-sm-12">
                    <div class="footer-end">
                        <div class="row">
                            <div class="col-xl-6 col-md-6 col-sm-6">
                                <p align="left" style="font-size: 11px"><i class="fa fa-copyright" aria-hidden="true"></i> {{ today | date:'y' }} Sodilibro</p>
                            </div>
                            <div class="col-xl-6 col-md-6 col-sm-6">
                                <p align="right" style="font-size: 11px"> Desarrollado por <a href="https://www.ecuasolutions.com/">Ecuasolutions</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
<!--footer section end -->
