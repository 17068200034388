import {Component, OnInit, Input} from '@angular/core';
import {FooterBaseComponent} from '../footer-base/footer-base.component';
import {LoginService} from '../../services/login.service';
import {CategoriasService} from '../../services/categorias.service';
import {AppConfig} from '../../../app.config';
import {ToastrService} from 'ngx-toastr';

@Component({
    selector: 'app-footer-two',
    templateUrl: './footer-two.component.html',
    styleUrls: ['./footer-two.component.scss']
})
export class FooterTwoComponent extends FooterBaseComponent implements OnInit {

    @Input() class: string;
    @Input() themeLogo: string = 'assets/images/icon/logo.png'; // default Logo
    @Input() mainFooter: boolean = true; // Default true
    @Input() subFooter: boolean = true; // Default false
    public urlshop = '';
    public today: number = Date.now();

    constructor(protected authService: LoginService, public categoriaService: CategoriasService, protected appConfig: AppConfig, protected toastrService: ToastrService) {
        super(authService, toastrService);
        this.urlshop = this.appConfig.getLayoutShop();
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

}
