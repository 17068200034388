import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {User} from '../classes/user';
import {Router} from '@angular/router';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {JwtHelperService} from '@auth0/angular-jwt';
import {AppConfig} from '../../app.config';
import {Empresa} from '../classes/empresa';


export const TOKEN_NAME = 'jwt_token';

@Injectable({
    providedIn: 'root'
})
export class LoginService {
    public url: string;

    private user$ = new BehaviorSubject<User>(null);
    private empresa$ = new BehaviorSubject<Empresa>(null);
    user = this.user$.asObservable();
    empresa = this.empresa$.asObservable();
    public urlshop = '';
    public tokenLogin: string;

    constructor(private http: HttpClient, private router: Router, protected config: AppConfig) {
        this.url = this.config.getEnv('API_URL');
        this.urlshop = this.config.getLayoutShop();
    }

    setEmpresa(empresa: Empresa) {
        this.empresa$.next(empresa);
    }

    changeUser(currentuser: User) {
        this.user$.next(currentuser);
    }

    clearUser() {
        this.user$.next(null);
    }

    login(datajson: string) {
        const url = `${this.url}/logincarrito/`;
        const headers = new HttpHeaders({'Content-Type': 'application/json', 'No-Auth': 'True'});
        return this.http.post(url, datajson, {headers, observe: 'response'}).pipe(map(res => res));
    }

    sendmail(datajson: string) {
        const url = `${this.url}/api/shopcart/contact/`;
        const headers = new HttpHeaders({'Content-Type': 'application/json', 'No-Auth': 'True'});
        return this.http.post(url, datajson, {headers, observe: 'response'}).pipe(map(res => res));
    }

    resetpassword(correo: string) {
        const url = `${this.url}/api/password_reset/`;
        const headers = new HttpHeaders({'Content-Type': 'application/json', 'No-Auth': 'True'});
        return this.http.post(url, {email: correo}, {headers}).pipe(map(res => res));
    }

    confirmpassword(data: any) {
        const url = `${this.url}/api/password_reset/confirm/ `;
        const headers = new HttpHeaders({'Content-Type': 'application/json', 'No-Auth': 'True'});
        return this.http.post(url, data, {headers}).pipe(map(res => res));
    }
    changepassword(data: any) {
        this.tokenLogin = this.getToken();
        const url = `${this.url}/api/resetpassword/`;
        const headers = new HttpHeaders({'Content-Type': 'application/json', 'No-Auth': 'True',  Authorization: 'JWT ' + this.tokenLogin});
        return this.http.post(url, data, {headers, observe: 'response'}).pipe(map(res => res));
    }

    getToken(): string {
        return localStorage.getItem(TOKEN_NAME);
    }

    getUsername(): string {
        return localStorage.getItem('username');
    }

    setUsername(user: string): void {
        localStorage.setItem('username', user);
    }

    getEmail(): string {
        return localStorage.getItem('email');
    }

    setEmail(email: string): void {
        localStorage.setItem('email', email);
    }

    getCodigoGrupo(): string {
        return localStorage.getItem('codgrupo');
    }

    getUserId() {
        return localStorage.getItem('user_id');
    }

// tslint:disable-next-line:variable-name
    setUserId(user_id: string) {
        localStorage.setItem('user_id', user_id);
    }

    // tslint:disable-next-line:variable-name
    setClienteId(cliente_id: string) {
        localStorage.setItem('cliente_id', cliente_id);
    }

    getClienteId() {
        return localStorage.getItem('cliente_id');
    }

    getNombre() {
        return localStorage.getItem('nombre');
    }

    setNombre(nombre: string) {
        localStorage.setItem('nombre', nombre);
    }

    getRuc() {
        return localStorage.getItem('codprovcli');
    }

    setRuc(codprovcli: string) {
        localStorage.setItem('codprovcli', codprovcli);
    }

    getTelefono() {
        return localStorage.getItem('telefono1');
    }

    setTelefono(telefono1: string) {
        localStorage.setItem('telefono1', telefono1);
    }

    getPais() {
        return localStorage.getItem('pais');
    }

    setPais(pais: string) {
        localStorage.setItem('pais', pais);
    }

    getProvincia() {
        return localStorage.getItem('provincia');
    }

    setProvincia(provincia: string) {
        localStorage.setItem('provincia', provincia);
    }

    getEmpresa() {
        return this.empresa;
    }

    getCanton() {
        return localStorage.getItem('canton');
    }

    setCanton(canton: string) {
        localStorage.setItem('canton', canton);
    }

    getParroquia() {
        return localStorage.getItem('parroquia');
    }

    setParroquia(parroquia: string) {
        localStorage.setItem('parroquia', parroquia);
    }

    getDireccion1() {
        return localStorage.getItem('direccion1');
    }

    setDireccion1(direccion1: string) {
        localStorage.setItem('direccion1', direccion1);
    }

    getSuperuser() {
        return localStorage.getItem('superuser');
    }

    setSuperuser(superuser: string) {
        localStorage.setItem('superuser', superuser);
    }

    setNumprecio(numprecio: string) {
        localStorage.setItem('numprecio', numprecio);
    }

    getNumprecio() {
        return localStorage.getItem('numprecio') || '0';
    }

    logout() {
        localStorage.removeItem(TOKEN_NAME);
        localStorage.removeItem('user_id');
        localStorage.removeItem('username');
        localStorage.removeItem('pais');
        localStorage.removeItem('provincia');
        localStorage.removeItem('canton');
        localStorage.removeItem('parroquia');
        localStorage.removeItem('codprovcli');
        localStorage.removeItem('telefono1');
        localStorage.removeItem('superuser');
        localStorage.removeItem('nombre');
        localStorage.removeItem('direccion1');
        localStorage.removeItem('email');
        localStorage.removeItem('transacciones');
        localStorage.removeItem('permisos');
        localStorage.removeItem('gnopcion');
        this.router.navigate(['/home']);
    }

    refresh(): void {
        window.location.reload();
    }

    setToken(token: string): void {
        localStorage.setItem(TOKEN_NAME, token);
    }

    isTokenExpired(token?: string): boolean {
        const helper = new JwtHelperService();
        if (!token) {
            token = this.getToken();
        }
        if (!token) {
            return true;
        }
        return helper.isTokenExpired(token);
    }

    getCurrentUser(): Observable<User> {
        this.tokenLogin = this.getToken();
        const httpOptions = {
            headers: new HttpHeaders({
                Authorization: 'JWT ' + this.tokenLogin
            })
        };
        const userid = this.getUserId();
        const url = `${this.url}/api/usuarios/${userid}/`;
        return this.http.get(url, httpOptions).pipe(map((result: User) => result));
    }

    getUser(): Observable<User> {
        const url = `${this.url}/api/usuarios/`;
        return this.http.get(url).pipe(map((result: User) => result));
    }

    updateCurrentUser(data: any) {
        this.tokenLogin = this.getToken();
        const httpOptions = {
            headers: new HttpHeaders({
                Authorization: 'JWT ' + this.tokenLogin
            })
        };
        const userid = this.getUserId();
        return this.http.put(`${this.url}/api/usuarios/${userid}/`, data, httpOptions).pipe(map(res => res));
    }
}

